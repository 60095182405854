import { connect } from 'react-redux'
import LogInForm from '../Components/LogInForm';
import {logIn,requestUserCreds} from '../actions/loginActions'
import {GET_TEXTS} from '../actions/textActions'
import {getTextsByUser} from '../Fetching/getTextsByUser'
import {checkLogin} from '../Fetching/login'
import {tryRegisterWithToken} from '../Fetching/registerTokens'
import { endHistoriesOnApi } from '../Fetching/history';

const mapStateToProps = (state) => {
  return {
    loggedIn: state.loggedIn,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogIn: (correct) => {dispatch(logIn(correct))},
    tryLogin: (username, password, login,token) => {dispatch(requestUserCreds()); checkLogin(username,password,login,dispatch,token);},
    tryRegister: (username,password,registerToken) => {tryRegisterWithToken(username,password,registerToken)},
    getUserTexts: () => {dispatch(GET_TEXTS); getTextsByUser(dispatch)},
    onClose: (isBeacon) => { endHistoriesOnApi(isBeacon)}
  }
}

const LogInConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(LogInForm)

export default LogInConnect;
