export const glossarTerms = (state = [false,[]], action) => {
  if (action.type === "GET_TERMS") {
    return [true,state[1]]
  }
  if (action.type === "SET_TERMS") {
    action.terms.sort((a,b) => {return (a.term.toLowerCase() > b.term.toLowerCase()) ? 1 : ((b.term.toLowerCase() > a.term.toLowerCase()) ? -1 : 0);} );
    return [false,action.terms]
  }
  if (action.type === "RESET" || action.type === "RESET_CHAPTER_TEXT") {
    return [false,[]]
  }
  return state
}
