import config from '../config';
import { toast } from 'react-toastify';
const Lokka = require('lokka').Lokka;
const Transport = require('lokka-transport-http').Transport;

export const clientgraph = new Lokka({
  transport: new Transport(config.apiUrl+'/graphql')
});

export const authenticateAndSendToAPI = (query,callback,errorFunc) => {
  var index = query.indexOf('(') + 1;
  var needParanthesis = false;
  if (index === 0) {
    index = getPosition(query, '{', 2);
    if (index === query.length) index = query.indexOf('}') - 1;
    needParanthesis = true;
  }

  var newQuery = query.slice(0, index) +
    (needParanthesis ? "(" : "") +   
   'token: "'+ window.localStorage.getItem('lb4Token') + '"' +
   (needParanthesis ? ")" : "")
    + query.slice(index);
  
  clientgraph.query(newQuery).then(
    result => {
      if (callback) {
        callback(result)
      }      
    },
    error => {
      if (error.message.includes("Token abgelaufen")) {
        let button = document.getElementById("logout")
        if (button && button != null) {
          document.getElementById("logout").click()
          toast.info("Sie wurden aufgrund eines abgelaufenen Tokens ausgeloggt.")
        }
      } 
      else if (!error.message.includes("GraphQL Error"))
      {
        toast.error("Api nicht erreichbar.")
        if(errorFunc) 
        {
          errorFunc(error)
        }
      }
      else if(errorFunc)
      {
        error.message = error.message.replace("GraphQL Error: ","")
        errorFunc(error)
      } else {
        toast.error("Es ist ein Fehler aufgetreten, bitte kontaktieren Sie einen Administrator.")
      }
    }
  );
}

//Suche das n-te Vorkommen eines Strings in einem String
function getPosition(string, subString, index) {
  return string.split(subString, index).join(subString).length;
}

