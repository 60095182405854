import {connect} from 'react-redux'
import TextSettings from '../Components/textSettings'
import {getAllClassesForText,updateClassesForText, renameText} from '../Fetching/texts'
import {getAllClasses} from '../Fetching/classes'

const mapDispatchToProps = dispatch => {
  return {
    onLoad: (textName) => {getAllClassesForText(dispatch,textName); getAllClasses(dispatch)},
    updateClassesForText: (textName, newClasses) => {updateClassesForText(dispatch, textName, newClasses)},
    renameText: (oldTextName, newTextName, newSubtitle) => {renameText(dispatch, oldTextName, newTextName, newSubtitle)}
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.loggedIn,
    classes: state.allClasses,
    textName: state.currentText.textName,
    subtitle: state.currentText.subtitle,
    textClasses: state.classesOfText
  }
}

const TextSettingsConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(TextSettings)

export default TextSettingsConnect;
