import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import LogInConnect from "./Connectors/LogInConnect";
import registerServiceWorker from "./registerServiceWorker";
import { createStore } from "redux";
import rootReducer from "./Reducers/rootReducer";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <Provider store={createStore(rootReducer)}>
    <div>
      <LogInConnect />
      <ToastContainer autoClose={3000} />
    </div>
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();
