import {SET_CLASSES} from '../actions/classActions'
import { toast } from 'react-toastify';
import {authenticateAndSendToAPI} from './connection'

export const getAllClasses = (dispatch) => {
  const query = `
  {
    classes
  }
  `

  authenticateAndSendToAPI(query,(result) => {
    dispatch(SET_CLASSES(result.classes.sort()))
  })
}

export const addNewClassToDatabase = (className, dispatch) => {
  const query = `
  mutation
  {
    addClass(className: "`+className+`")
  }
  ` 

  authenticateAndSendToAPI(query,(result) => {
    if (result.addClass) {
      toast.success("Klasse/Gruppe erfolgreich hinzugefügt")
      getAllClasses(dispatch);
    } else {
      toast.error("Klasse konnte nicht hinzugefügt werden")
    }
  })
}

export const deleteClassFromDatabase = (className, dispatch) => {
  const query = `
  mutation
  {
    deleteClass(className: "`+className+`")
  }
  `

  authenticateAndSendToAPI(query,(result) => {
    if (result.deleteClass) {
      toast.success("Klasse/Gruppe erfolgreich gelöscht")
      getAllClasses(dispatch);
    } else {
      toast.error("Klasse konnte nicht gelöscht werden")
    }
  })
}
