import React, { Component } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

import "../react-select.css";

let clicked;

class EditGlossar extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedDelete: -1, selectedAdd: -1, termId: -1 };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    if (clicked === "add") {
      if (
        event.target.term.value === "" ||
        this.state.selectedAdd === -1 ||
        this.state.selectedAdd.length === 0
      ) {
        toast.error("Der Name und ein Kapitel müssen ausgewählt sein!");
      } else {
        this.props.addGlossarTerm(
          this.props.textName,
          this.state.selectedAdd,
          event.target.term.value
        );
        this.setState({ selectedAdd: -1 });
      }
    }

    if (clicked === "edit") {
      if (
        event.target.term.value === "" ||
        this.state.selectedAdd === -1 ||
        this.state.selectedAdd.length === 0 ||
        this.state.termId === -1
      ) {
        toast.error(
          "Der Name, ein Eintrag und ein Kapitel müssen ausgewählt sein!"
        );
      } else {
        this.props.editGlossarTerm(
          this.props.textName,
          this.state.selectedAdd,
          event.target.term.value,
          this.state.termId
        );
      }
    }

    if (clicked === "delete") {
      if (this.state.selectedDelete === -1) {
        toast.error("Ein Eintrag zum Löschen muss ausgewählt sein!");
      } else {
        this.props.deleteGlossarTerm(
          this.props.textName,
          this.state.selectedDelete.value
        );
        this.setState({ selectedDelete: -1 });
      }
    }
  }

  getSelect(isAdd) {
    if (isAdd) {
      return this.props.chapters[1].map(chapter => {
        return { value: chapter.chapterId, label: chapter.chapterName };
      });
    } else {
      return this.props.glossarTerms[1].map(term => {
        return { value: term.termId, label: term.term };
      });
    }
  }

  getDisplay(style) {
    return (
      <div>
        <h1 style={{ textAlign: "center" }}>Glossar bearbeiten</h1>
        <br />
        <form method="post" onSubmit={this.handleSubmit}>
          <div className="container form-group ">
            <label className="control-label">
              <b>Glossareintrag zum löschen/bearbeiten:</b>
            </label>
            <Select
              name="form-field-name"
              isMulti={false}
              value={this.state.selectedDelete}
              options={this.getSelect(false)}
              noResultsText="Es wurde kein Eintrag gefunden"
              placeholder="Eintrag auswählen"
              onChange={value => {
                this.handleEditDeleteChange(value);
              }}
            />
            <label className="control-label">
              <b>Name des Eintrags: </b>
            </label>
            <br />
            <input
              id="term"
              type="text"
              className="fullInput form-control"
              placeholder="Eintrag"
              name="term"
            />
            <label className="control-label">
              <b>Glossareintrag verweist auf folgende Kapitel:</b>
            </label>
            <Select
              name="form-field-name"
              isMulti={true}
              value={this.state.selectedAdd}
              options={this.getSelect(true)}
              noResultsText="Es wurde kein Kapitel gefunden"
              placeholder="Kapitel auswählen"
              onChange={value => {
                if (value === null) 
                {
                  value = []
                }
                this.setState({ selectedAdd: value });
              }}
            />
            <br />
            <button
              className="btn "
              onClick={() => {
                clicked = "add";
              }}
              type="submit"
            >
              Eintrag erstellen
            </button>
            <button
              className="btn "
              style={{ marginLeft: "5px" }}
              onClick={() => {
                clicked = "edit";
              }}
              type="submit"
            >
              Eintrag bearbeiten
            </button>
            <button
              className="btn right"
              onClick={() => {
                clicked = "delete";
              }}
              type="submit"
            >
              Eintrag löschen
            </button>
          </div>
        </form>
      </div>
    );
  }

  getTermById(id) {
    for (let index = 0; index < this.props.glossarTerms[1].length; index++) {
      const element = this.props.glossarTerms[1][index];
      if (element.termId === id) {
        return element;
      }
    }
  }

  getChaptersForTermById(chapterIds) {
    var ids = chapterIds.split(",");
    var chapters = [];
    for (let index = 0; index < this.props.chapters[1].length; index++) {
      const chapter = this.props.chapters[1][index];
      if (ids.includes(chapter.chapterId + "")) {
        chapters.push({ value: chapter.chapterId, label: chapter.chapterName });
      }
    }
    return chapters;
  }

  handleEditDeleteChange(obj) {
    if (obj !== null) {
      var term = this.getTermById(obj.value);
      this.setState({
        selectedDelete: obj,
        selectedAdd: this.getChaptersForTermById(term.chapterIds),
        termId: obj.value
      });
      document.getElementById("term").value = term.term;
    }
  }

  render() {
    const style = {
      display: "-webkit-flex",
      WebkitFlex: "0 1 auto",
      flex: "0 1 auto",
      WebkitFlexDirection: "column",
      flexDirection: "column",
      WebkitFlexGrow: 1,
      flexGrow: 1,
      WebkitFlexShrink: 0,
      flexShrink: 0,
      WebkitFlexBasis: "25%",
      flexBasis: "25%",
      maxWidth: "25%",
      height: "200px",
      WebkitAlignItems: "center",
      alignItems: "center",
      WebkitJustifyContent: "center",
      justifyContent: "center"
    };
    return this.getDisplay(style);
  }
}

export default EditGlossar;
