export const CHAPTER_OPENED = "CPT_OPN"
export const CHAPTER_SCROLLED_DOWN = "CPT_SCD"
export const CHAPTER_SCROLLED_UP = "CPT_SCU"



export const GLOSSAR_USED = "GLO_USE"

export const NOTE_CREATED = "NOT_CRE"
export const NOTEAREA_OPENED = "NOT_OPN"

export const MEDIUM_EXPANDED = "MDM_EXP"
export const MEDIUM_RESIZED = "MDM_RES"
export const EXTERNAL_MEDIUM_OPENED = "MDM_EXT"
export const INTERNAL_MEDIUM_OPENED = "MDM_OPN"