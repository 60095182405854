export const setTexts = (state = [false, []], action) => {
  if (action.type === "SET_TEXTS") {
    
    return [false, action.newTexts]
  }
  if (action.type === "GET_TEXTS") {
    return [true, state[1]]
  }
  if (action.type === "RESET") {
    return [false, []]
  }
  return state
}

export const currentText = (state = {textName: "", isReadOnly: 1}, action) => {
  if (action.type === "SET_SCHEMA") {
    return action.newSchema
  }
  if (action.type === "RESET_SCHEMA") {
    return {textName: "", isReadOnly: 1}
  }
  if (action.type === "RESET") {
    return {textName: "", isReadOnly: 1}
  }
  return state
}

export const classesOfText = (state = [false, []], action) => {
  if (action.type === "SET_TEXT_CLASSES") {
    return [false, action.classes]
  }
  if (action.type === "GET_TEXT_CLASSES") {
    return [true, state[1]]
  }
  if (action.type === "RESET") {
    return [false,[]]
  }
  return state
}
