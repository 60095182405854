import React, { Component } from "react";
import Select from "react-select";
import { deleteHistoryEntriesForUser } from "../Fetching/history";

import "../react-select.css";

let clicked;

let roles = [
  { value: 0, label: "Leser" },
  { value: 1, label: "Autor" },
  { value: 2, label: "Administrator" }
]

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userClass: "",
      nameClass: "",
      newClassClass: "",
      selectedUser: { username: "", klasse: "", role: 0 },
      selectedUserForSelectComponent: "",
      selectedClass: "",
      roleDropdown: 0
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.props.onLoad();
  }

  handleSubmit(event) {
    event.preventDefault();
    let error = false;

    if (clicked === "deleteHistory") {
      if (this.state.selectedUserForSelectComponent.value === "") {
        error = true;
        this.setState({ userClass: "has-error" });
        return;
      }

      deleteHistoryEntriesForUser(this.state.selectedUserForSelectComponent.value);
      return;
    }

    if (clicked === "edit") {
      if (this.state.selectedUserForSelectComponent === "") {
        error = true;
        this.setState({ userClass: "has-error" });
        return;
      }

      if (event.target.displayText.value === "") {
        error = true;
        this.setState({ nameClass: "has-error" });
      }

      if (!error) {
        let newName = "";
        if (event.target.displayText.value !== this.state.selectedUserForSelectComponent.value) {
          newName = event.target.displayText.value;
        }

        this.props.updateUser(
          this.state.selectedUserForSelectComponent.value,
          newName,
          this.state.selectedClass.value,
          event.target.newPassword.value,
          this.state.roleDropdown
        );
        this.setState({
          selectedUser: { username: "", klasse: "", role: 0 },
          selectedUserForSelectComponent: "",
          selectedClass: "",
          roleDropdown: 0
        });
      }
    } else {
      if (this.state.selectedUserForSelectComponent !== "") {
        this.props.onClickDeleteUser(this.state.selectedUserForSelectComponent.value);
        this.setState({
          selectedUser: { username: "", klasse: "", role: 0 },
          selectedUserForSelectComponent: "",
          selectedClass: "",
          roleDropdown: 0
        });
      } else {
        this.setState({ userClass: "has-error" });
      }
    }
  }

  getMainSelect() {
    return this.props.users[1].map(user => {
      return { value: user.username, label: user.username };
    });
  }

  getClassSelect() {
    return this.props.classes[1].map(data => {
      return { value: data, label: data };
    });
  }

  updateFields(value) {
    var user = this.props.users[1].filter(user => {
      return user.username === value.value;
    });

    this.setState({
      selectedUser: user[0],
      selectedUserForSelectComponent: value,
      selectedClass: {value: user[0].klasse, label: user[0].klasse},
      roleDropdown: user[0].role
    });
    document.getElementById("name").value = value.value;
  }

  getDisplay(style) {
    return (
      <div>
        <h1 style={{ textAlign: "center" }}>Nutzerverwaltung</h1>
        <br />
        <form method="post" onSubmit={this.handleSubmit}>
          <div className="container form-group ">
            <div
              style={{ borderBottomStyle: "solid" }}
              className={this.state.userClass}
            >
              <label className="control-label">
                <b>Nutzer auswählen: </b>
              </label>
              <br />
              <Select
                name="form-field-name"
                value={this.state.selectedUserForSelectComponent}
                clearable={true}
                options={this.getMainSelect()}
                noResultsText="Kein Nutzer wurde gefunden"
                placeholder="Nutzer zum bearbeiten auswählen"
                onChange={value => {
                  if (!(value === null)) this.updateFields(value);
                }}
              />
              <br />
              {this.state.userClass === "has-error" ? (
                <div>
                  <span className="help-block">
                    Bitte zuerst einen Nutzer auswählen!
                  </span>
                  <br />
                </div>
              ) : (
                ""
              )}
            </div>
            <br />
            <br />
            <div className={this.state.nameClass}>
              <label className="control-label">
                <b>Name: </b>
              </label>
              <br />
              <input
                id="name"
                type="text"
                className="fullInput form-control"
                placeholder="Name des Nutzers"
                name="displayText"
              />
              {this.state.nameClass === "has-error" ? (
                <div>
                  <span className="help-block">
                    Bitte dieses Feld ausfüllen
                  </span>
                  <br />
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              <label className="control-label">
                <b>Neues Passwort: </b>
              </label>
              <br />
              <input
                id="password"
                type="text"
                className="fullInput form-control"
                placeholder="Leer lassen, wenn dieses nicht geändert werden soll!"
                name="newPassword"
              />
            </div>
            <div className={this.state.newClassClass}>
              <label className="control-label">
                <b>Klasse: </b>
              </label>
              <br />
              <Select
                name="form-field-name"
                value={this.state.selectedClass}
                clearable={true}
                options={this.getClassSelect()}
                noResultsText="Keine Klasse wurde gefunden"
                placeholder="Klasse des ausgewählten Nutzers"
                onChange={value => {
                  if (!(value === null))
                    this.setState({ selectedClass: value });
                }}
              />
              {this.state.newClassClass === "has-error" ? (
                <div>
                  <span className="help-block">
                    Bitte dieses Feld ausfüllen
                  </span>
                  <br />
                </div>
              ) : (
                ""
              )}
            </div>
            <br />
            <div>
              <label className="control-label">
                <b>Rolle: </b>
                <Select
                  name="form-field-name"
                  value={roles.filter(role => role.value == this.state.roleDropdown)}
                  clearable={false}
                  options={roles}
                  noResultsText="Fehler!"
                  placeholder="Role des ausgewählten Nutzers"
                  onChange={value => {
                    if (!(value === null))
                      this.setState({ roleDropdown: value.value });
                  }}
                />
              </label>
            </div>
            <br />
            <button
              className="btn "
              onClick={() => {
                clicked = "edit";
              }}
              type="submit"
            >
              Nutzer speichern
            </button>
            <button
              style={{ marginLeft: "2px" }}
              className="btn "
              onClick={() => {
                clicked = "deleteHistory";
              }}
              type="submit"
            >
              Verlauf löschen
            </button>
            <button
              className="btn right"
              onClick={() => {
                clicked = "delete";
              }}
              type="submit"
            >
              Nutzer löschen
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    const style = {
      display: "-webkit-flex",
      WebkitFlex: "0 1 auto",
      flex: "0 1 auto",
      WebkitFlexDirection: "column",
      flexDirection: "column",
      WebkitFlexGrow: 1,
      flexGrow: 1,
      WebkitFlexShrink: 0,
      flexShrink: 0,
      WebkitFlexBasis: "25%",
      flexBasis: "25%",
      maxWidth: "25%",
      height: "200px",
      WebkitAlignItems: "center",
      alignItems: "center",
      WebkitJustifyContent: "center",
      justifyContent: "center"
    };
    return this.getDisplay(style);
  }
}

export default ManageUsers;
