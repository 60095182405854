import {SET_TERMS} from '../actions/glossarActions'
import { toast } from 'react-toastify';
import {authenticateAndSendToAPI} from './connection'

export const getGlossarTermsFromApi = (dispatch, textName) => {
  const query = `
  {
    textGlossarTerms(textName: "`+textName +`")
    {
      term
      termId
      chapterIds
      versions
      chapternames
      quizUrls
    }
  }
  `

  authenticateAndSendToAPI(query, result => {
    dispatch(SET_TERMS(result.textGlossarTerms))
  })
}

export const addGlossarTermToApi = (dispatch, chapterObjs, textName, term) => {
  let chapterIdString = ""
  for (let index = 0; index < chapterObjs.length; index++) {
    chapterIdString += chapterObjs[index].value + ",";
  }
  chapterIdString = chapterIdString.substring(0, chapterIdString.length - 1);
  const query = `
  mutation
  {
    addGlossarTerm(chapterIds: "`+chapterIdString+`" textName: "`+ textName + `" term: "`+term+`")
  }
  `
  authenticateAndSendToAPI(query,(data) =>{
    if (!data.addGlossarTerm) {
       toast.error("Der Glossareintrag konnte nicht Hinzugefügt werden")
    } else {
      toast.success("Der Glossareintrag wurde erfolgreich Hinzugefügt")
      getGlossarTermsFromApi(dispatch,textName)
    }
  });
}

export const editGlossarTermOnApi = (dispatch,chapterObjs, textName,term,termId) => {
  let chapterIdString = ""
  for (let index = 0; index < chapterObjs.length; index++) {
    chapterIdString += chapterObjs[index].value + ",";
  }
  chapterIdString = chapterIdString.substring(0, chapterIdString.length - 1);
  const query = `
  mutation
  {
    editGlossarTerm(chapterIds: "`+chapterIdString+`" textName: "`+ textName + `" term: "`+term+`" termId: `+termId+`)
  }
  `
  authenticateAndSendToAPI(query,(data) =>{
    if (!data.editGlossarTerm) {
       toast.error("Der Glossareintrag konnte nicht bearbeitet werden")
    } else {
      toast.success("Der Glossareintrag wurde erfolgreich bearbeitet")
      getGlossarTermsFromApi(dispatch,textName)
    }
  });
}


export const deleteGlossarTermFromApi = (dispatch,termId,textName) => {
  const query = `
  mutation
  {
    deleteGlossarTerm(termId: `+termId+` textName: "`+textName+`")
  }
  `

  authenticateAndSendToAPI(query,(data) =>{
    if (!data.deleteGlossarTerm) {
       toast.error("Der Glossareintrag konnte nicht entfernt werden")
    } else {
      toast.success("Der Glossareintrag wurde erfolgreich entfernt")
      getGlossarTermsFromApi(dispatch,textName)
    }
  });
}