export const history = (state = [], action) => {
  switch (action.type) {
    case 'SET_HISTORY':
      return action.history.map((entry) => {
        if (entry.duration === -1) {
          entry.duration = ''
          entry.endTimestamp = ''
        } else {
          entry.duration = makeTimeString(entry.duration)
          entry.endTimestamp = new Date(entry.endTimestamp).toLocaleString("de-de")
        }
        entry.timestamp = new Date(entry.timestamp).toLocaleString("de-de")
        return entry
      })
    case 'LOG_OUT':
      return [];
    default:
      return state;
  }
}

const makeTimeString = (seconds) => {
  if (seconds === 0) {
    return 'weniger als eine Sekunde'
  }

  var h = Math.floor(seconds / 3600);
  var m = Math.floor(seconds % 3600 / 60);
  var s = Math.floor(seconds % 3600 % 60);
  var hDisplay = h > 0 ? h + (h === 1 ? " Stunde, " : " Stunden, ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " Minute, " : " Minuten, ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " Sekunde" : " Sekunden") : "";
  return hDisplay + mDisplay + sDisplay;
}
