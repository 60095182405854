import React from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from "react-sortable-hoc";
import ReactQuill from "react-quill";
import config from "../config";

import "react-quill/dist/quill.snow.css";
import "../react-select.css";
import { sanatizeString } from "../common";

const axios = require("axios");
var $ = require("jquery");
let clicked = "";
let modalInstance;

const SortableItem = SortableElement(({ value }) => (
  <li className="SortableItem">{value}</li>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul className="SortableList">
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          value={value.displayText}
        />
      ))}
    </ul>
  );
});

class ManageMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaNameClass: "",
      otherDataClass: "",
      selected: "",
      editIndexMode: false,
      media: this.props.media[1],
      text: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    var elems = document.querySelectorAll(".modal");

    modalInstance = window.M.Modal.init(elems, {
      dismissible: false,
      startingTop: "10%"
    })[0];
  }

  componentDidUpdate() {
    if (
      this.state.media.length !== this.props.media[1].length ||
      clicked === "edit"
    ) {
      this.setState({ media: this.props.media[1] });
      clicked = "";
    }
  }

  handleChange(value) {
    this.setState({ text: value });
  }

  handleSubmit(event) {
    event.preventDefault();

    let error = false;

    if (clicked === "edit") {
      const displayText = event.target.displayText.value;
      if (this.props.chapterId === 0) {
        error = true;
        toast.error("Bitte zunächst ein Kapitel auswählen");
      }
      if (this.state.selected === "") {
        error = true;
        toast.error("Bitte zunächst ein Medium auswählen");
      }
      if (displayText === "") {
        error = true;
        this.setState({ mediaNameClass: "has-error" });
      }

      if (error) {
        return
      }

      this.props.onMediaEdited(
        this.props.chapterId,
        displayText,
        this.state.selected.value,
        this.props.textName
      );

      error = true;
    }

    if (clicked === "add") {
      const displayText = event.target.displayText.value;
      const childData = this.state.text.replace(/"/g, '\\"');

      if (displayText === "") {
        error = true;
        this.setState({ mediaNameClass: "has-error" });
      }
      if (
        event.target.url.value !== "" &&
        childData !== "" &&
        event.target.mediaImage.files[0] !== undefined
      ) {
        error = true;
        this.setState({ otherDataClass: "has-error" });
      }
      if (this.props.chapterId === 0) {
        error = true;
        toast.error("Bitte zunächst ein Kapitel auswählen");
      }

      if (!error) {
        if (event.target.mediaImage.files[0] !== undefined) {
          let mydata = new FormData();
          mydata.append("File", event.target.mediaImage.files[0]);
          mydata.append("textName", this.props.textName);
          mydata.append("chapterId", this.props.chapterId);
          mydata.append("displayText", displayText);
          mydata.append("uploadType", "Media");

          modalInstance.open();
          axios
            .request({
              url: config.apiUrl + "/api/upload",
              method: "POST",
              data: mydata,
              onUploadProgress: p => {
                document.getElementById("progressBar").style.width =
                  (p.loaded / p.total) * 100 + "%";
                $("#progress").text(
                  Math.floor((p.loaded / p.total) * 100) + "%"
                );
              }
            })
            .then(response => {
              modalInstance.close();
              if (response.status === 201) {
                toast.success("Medium wurde erfolgreich hinzugefügt!");
                this.props.refreshMedia(
                  this.props.chapterId,
                  this.props.textName
                );
              } else {
                toast.error(
                  "Beim hochladen des Mediums ist ein Fehler aufgetreten"
                );
              }
            })
            .catch(error => {
              modalInstance.close();
              toast.error(
                "Beim hochladen des Mediums ist ein Fehler aufgetreten"
              );
            });
        } else {
          this.props.onMediaAdd(
            this.props.chapterId,
            displayText,
            event.target.url.value,
            childData,
            this.props.textName
          );
        }
      }
    } else if (clicked === "delete") {
      if (this.state.selected !== "") {
        this.props.onMediaDelete(
          this.state.selected.value,
          this.props.chapterId,
          this.props.textName
        );
        event.target.mediaImage.value = "";
        this.setState({ selected: "" });
      }
    }
  }

  getSelect() {
    return this.props.media[1].map((data, index) => {
      return { value: data.mediaId, label: data.displayText };
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      media: arrayMove(this.state.media, oldIndex, newIndex)
    });
  };

  getButtonsForToggle() {
    if (this.props.media[1].length > 0) {
      return (
        <div className="row">
          <div className="col s10 offset-s1">
            <a
              className="btn"
              onClick={() => {
                this.setState({ editIndexMode: true });
              }}
              href="#!"
            >
              Reihenfolge bearbeiten
            </a>
            <a
              className="btn right"
              onClick={() => {
                this.setState({ editIndexMode: false });
              }}
              href="#!"
            >
              Medien bearbeiten
            </a>
          </div>
        </div>
      );
    }
  }

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" }
      ],
      [{ color: [] }, { background: [] }],
      ["clean"]
    ]
  };

  getDisplay() {
    if (this.state.editIndexMode) {
      return (
        <div style={{ height: "100%" }}>
          <div className="row">
            <br />
            <div className="row">
              <div className="col s10 offset-s1">
                <SortableList
                  items={this.state.media}
                  onSortEnd={this.onSortEnd}
                  lockAxis="y"
                  lockToContainerEdges={true}
                  helperClass="SortableHelper"
                />
                <br />
                <button
                  className="btn"
                  onClick={() => {
                    this.props.editMediaOrder(
                      this.state.media,
                      this.props.chapterId,
                      this.props.textName
                    );
                  }}
                >
                  Änderungen speichern
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ height: "100%" }}>
          <div id="updateProgress" className="modal">
            <div className="modal-content">
              <div className="row">
                <h4 className="col s10">Datei wird hochgeladen</h4>
                <h4 id="progress" className="col s2 right-align">
                  0%
                </h4>
              </div>
              <div
                className="progress big"
                style={{ height: "25px !important" }}
              >
                <div
                  id="progressBar"
                  className="determinate"
                  style={{ width: "0%", height: "25px" }}
                ></div>
              </div>
            </div>
          </div>
          <br />
          <form method="post" onSubmit={this.handleSubmit}>
            <div className="container form-group ">
              <div className={this.state.mediaNameClass}>
                <label className="control-label">
                  <b>Anzeigetext des Mediums: </b>
                </label>
                <br />
                <input
                  type="text"
                  className="fullInput form-control"
                  placeholder="Anzeigetext"
                  name="displayText"
                  id="displayTextInput"
                />
                {this.state.mediaNameClass === "has-error" ? (
                  <div>
                    <span className="help-block">
                      Bitte dieses Feld ausfüllen
                    </span>
                    <br />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={this.state.otherDataClass}>
                <label className="control-label">
                  <b>Medium auswählen:</b>
                </label>
                <br />
                <div className="file-field input-field">
                  <div className="btn">
                    <span>Datei auswählen</span>
                    <input
                      type="file"
                      id="mediaImage"
                      name="images"
                      accept=".mp4, .webm, .ogg, .jpg, .jpeg, .png, .gif, .svg, .pdf, .xls, .xlsx, .doc, .docx"
                    />
                  </div>
                  <div className="file-path-wrapper">
                    <input className="file-path validate" type="text" />
                  </div>
                </div>
                <label className="control-label">
                  <b>Oder Link eingeben: </b>
                </label>
                <br />
                <input
                  type="text"
                  className="fullInput form-control"
                  placeholder="Direktlink zu Bild/Video"
                  name="url"
                />
                <label className="control-label">
                  <b>Text: </b>
                </label>
                <br />
                <ReactQuill
                  value={this.state.text}
                  onChange={this.handleChange}
                  modules={this.modules}
                  formats={this.formats}
                />
                {this.state.otherDataClass === "has-error" ? (
                  <div>
                    <span className="help-block">
                      Bitte nur ein Feld ausfüllen (URL oder Text)
                    </span>
                    <br />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <button
                className="btn"
                onClick={() => {
                  clicked = "add";
                }}
                type="submit"
              >
                Neues Medium Hinzufügen
              </button>
              <br />
              <br />
              <br />

              <label className="control-label">
                <b>Medium zum löschen/bearbeiten auswählen:</b>
              </label>
              <Select
                theme="snow"
                name="form-field-name"
                value={this.state.selected}
                options={this.getSelect()}
                noResultsText="Kein Medium wurde gefunden"
                onChange={value => {
                  if (!(value === null)) {
                    this.setState({
                      selected: value
                    });
                    document.getElementById("displayTextInput").value =
                      value.label;
                  }
                }}
              />
              <br />
              <br />
              <button
                className="btn "
                onClick={() => {
                  clicked = "delete";
                }}
                type="submit"
              >
                Medium löschen
              </button>
              <button
                style={{ marginLeft: "5px" }}
                className="btn "
                onClick={() => {
                  clicked = "edit";
                }}
                type="submit"
              >
                Medium-Name bearbeiten
              </button>
            </div>
          </form>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this.getButtonsForToggle()}
        {this.getDisplay()}
      </div>
    );
  }
}

export default ManageMedia;
