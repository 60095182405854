import { SET_USERS } from "../actions/userActions";
import { toast } from "react-toastify";
import { sha512 } from "js-sha512";

import { authenticateAndSendToAPI } from "./connection";

export const getAllUsersInfo = dispatch => {
  const query = `
  {
    users()
    { 
      username
      klasse 
      role
    }
  }
  `;

  authenticateAndSendToAPI(query, result => {
    dispatch(SET_USERS(result.users));
  });
};

export const deleteUser = (username, dispatch) => {
  const query =
    `
  mutation
  {
    deleteUser(userNameToDelete: "` +
    username +
    `")
  }
  `;
  authenticateAndSendToAPI(query, result => {
    if (result.deleteUser) {
      getAllUsersInfo(dispatch);
      toast.success("Nutzer wurde erfolgreich gelöscht!");
    } else {
      toast.error("Nutzer konnte nicht gelöscht werden.");
    }
  });
};

export const updateUser = (
  dispatch,
  oldName,
  newName,
  className,
  newPassword,
  role
) => {
  let newnewPassword =
    newPassword === "" ? "" : sha512(newPassword).toUpperCase();

  const query =
    `
  mutation
  {
    editUser(oldName: "` +
    oldName +
    `" newName: "` +
    newName +
    `" className: "` +
    className +
    `" role: ` +
    role +
    ` newPassword: "` +
    newnewPassword +
    `")
  }
  `;

  authenticateAndSendToAPI(query, result => {
    if (result.editUser) {
      getAllUsersInfo(dispatch);
      toast.success("Nutzer erfolgreich geändert!");
    } else {
      toast.error("Nutzer konnte nicht bearbeitet werden!");
    }
  });
};
