import { SET_CHAPTERS, SET_CHAPTER_TEXT, SET_QUIZ_URL } from '../actions/chapterActions'
import { toast } from 'react-toastify';
import { authenticateAndSendToAPI } from './connection'
import { endHistoriesForChapterOnApi, endHistoryEntryOnApi, writeHistoryOnApi } from './history';
import { CHAPTER_OPENED } from '../constants/history';
import { sanatizeString } from '../common';

export const getChaptersFromApi = (dispatch, textName) => {
  const query = `
  {
    chapters(textName: "`+ textName + `")
    {
      chapterId
      chapterName
      version
      logoUrl
      quizUrl
      isVisible
    }
  }
  `
  authenticateAndSendToAPI(query, result => {
    dispatch(SET_CHAPTERS(result.chapters))
  })
}

export const loadChapterFromApi = (dispatch, chapterId, chapterName, textName) => {
  let query = `
  {
    chapter(chapterId: `+ chapterId + ` textName: "` + textName + `")
    {
      chapterText
      markers
      {
        startIndex
        startTag
        endTag
        length
      }
      version
      logoUrl
      quizUrl
      isVisible
    }
  }
  `
  authenticateAndSendToAPI(query, (result) => {
    dispatch(SET_CHAPTER_TEXT(result.chapter.chapterText, chapterId, chapterName, result.chapter.version, result.chapter.logoUrl, result.chapter.markers, result.chapter.isVisible))
    dispatch(SET_QUIZ_URL(result.chapter.quizUrl))
    writeHistoryOnApi(textName, chapterId, CHAPTER_OPENED)
  });
}

export const addChapterToApi = (dispatch, chapterName, textName) => {
  const query = `
  mutation
  {
     addChapter(chapterName: "`+ sanatizeString(chapterName) + `" textName: "` + textName + `")
  }
  `
  authenticateAndSendToAPI(query, (result) => {
    if (!result.addChapter) {
      toast.error("Das Kapitel konnte nicht Hinzugefügt werden")
    } else {
      toast.success("Das Kapitel wurde erfolgreich Hinzugefügt")
      getChaptersFromApi(dispatch, textName)
    }
  });
}

export const deleteChapterFromApi = (dispatch, chapterId, textName) => {
  const query = `
  mutation
  {
     deleteChapter(chapterId: `+ chapterId + ` textName: "` + textName + `")
  }
  `
  authenticateAndSendToAPI(query, (result) => {
    if (!result.deleteChapter) {
      toast.error("Das Kapitel konnte nicht entfernt werden")
    } else {
      toast.success("Das Kapitel wurde erfolgreich entfernt")
      getChaptersFromApi(dispatch, textName)
    }
  });
}

export const editChapterOnApi = (dispatch, chapterId, chapterName, chapterText, textName, logoUrl, silent, isVisible) => {
  const query = `
  mutation
  {`+
    // eslint-disable-next-line
    ` editChapter(chapterText: "` + sanatizeString(chapterText) + `" isVisible: `+ isVisible +` chapterId: ` + chapterId + ` chapterName: "` + sanatizeString(chapterName) + `" textName: "` + textName + `" logoUrl: "` + logoUrl + `")
  }
  `
  authenticateAndSendToAPI(query, (result) => {
    if (!result.editChapter) {
      toast.error("Das Kapitel konnte nicht bearbeitet werden")
    } else if (!silent) {
      toast.success("Das Kapitel wurde erfolgreich bearbeitet")
      getChaptersFromApi(dispatch, textName)
    }
  });
}

export const addQuizToChapter = (dispatch, textName, chapterId, quizUrl) => {
  const query = `
  mutation
  {
    addQuiz(
      chapterId: `+ chapterId + ` textName: "` + textName + `" quizUrl: "` + quizUrl + `" 
    )
  }
  `
  authenticateAndSendToAPI(query, (data) => {
    if (!data.addQuiz) {
      toast.error("Das Quiz konnte nicht bearbeitet werden")
    } else {
      toast.success("Das Quiz wurde erfolgreich bearbeitet")
      dispatch(SET_QUIZ_URL(quizUrl))
    }
  });
}
