import React from "react";
import Select from "react-select";

import "../react-select.css";

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmClass: "",
      usernameClass: "",
      passwordClass: "",
      selectedClass: "",
      selected: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.props.onLoad();
  }

  componentDidMount = () => {
    window.M.updateTextFields();
  };

  handleSubmit(event) {
    event.preventDefault();

    let error = false;
    if (event.target.uname.value === "") {
      error = true;
      this.setState({ usernameClass: "has-error" });
    }
    if (event.target.psw.value === "") {
      error = true;
      this.setState({ passwordClass: "has-error" });
    }
    if (event.target.psw.value !== event.target.confirm.value) {
      error = true;
    }
    if (this.state.selected === "") {
      error = true;
      this.setState({ selectedClass: "has-error" });
    }

    if (!error) {
      const username = event.target.uname.value;
      const password = event.target.psw.value;
      this.props.tryToRegister(username, password, this.state.selected.value);
    }
  }

  getSelectOptions() {
    return this.props.classes[1].map((data, index) => {
      return <option value={data}>{data}</option>;
    });
  }

  getSelect() {
    return this.props.classes[1].map((data, index) => {
      return { value: data, label: data };
    });
  }

  getDisplay() {
    return (
      <div className="row">
        <h1 style={{ "text-align": "center" }}>Registrierung</h1>
        <form method="post" onSubmit={this.handleSubmit}>
          <div className="container form-group">
            <div className={this.state.usernameClass}>
              <div className="input-field">
                <input
                  id="username"
                  type="text"
                  className="validate"
                  name="uname"
                  placeholder="Nutzername eingeben:"
                />
                <label for="username">
                  <b>Nutzername</b>
                </label>
              </div>
              {this.state.usernameClass === "has-error" ? (
                <div>
                  <span className="help-block">
                    Bitte einen Nutzernamen eingeben!
                  </span>
                  <br />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={this.state.passwordClass}>
              <div className="input-field">
                <input
                  id="password"
                  type="password"
                  className="validate"
                  name="psw"
                  placeholder="Passwort eingeben:"
                />
                <label for="password">
                  <b>Passwort</b>
                </label>
              </div>
              {this.state.passwordClass === "has-error" ? (
                <div>
                  <span className="help-block">
                    Bitte ein Passwort eingeben!
                  </span>
                  <br />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={this.state.confirmClass}>
              <div className="input-field">
                <input
                  id="confirm"
                  type="password"
                  className="validate"
                  name="confirm"
                  placeholder="Passwort wiederholen:"
                />
                <label for="confirm">
                  <b>Passwort</b>
                </label>
              </div>
              {this.state.confirmClass === "has-error" ? (
                <div>
                  <span className="help-block">
                    Passwörter stimmen nicht überein!
                  </span>
                  <br />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={this.state.selectedClass}>
              <div className="input-field">
                <select id="klasse" name="klasse">
                  <option value="" disabled selected>
                    Klasse auswählen
                  </option>
                  {this.getSelectOptions()}
                </select>
                <label>Klasse:</label>
              </div>
              <Select
                name="form-field-name"
                value={this.state.selected}
                options={this.getSelect()}
                placeholder="Klasse auswählen"
                noResultsText="Keine Klasse gefunden"
                onChange={value => {
                  if (!(value === null))
                    this.setState({ selected: value });
                }}
              />
              {this.state.selectedClass === "has-error" ? (
                <div>
                  <span className="help-block">
                    Bitte eine Klasse/Gruppe auswählen!
                  </span>
                  <br />
                </div>
              ) : (
                ""
              )}
            </div>
            <br />
            <button className="btn-large green" type="submit">
              User anlegen
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return this.getDisplay();
  }
}

export default RegisterForm;
