export const changeCurrentIndex = (state = 0 , action) => {
  if (action.type === "CHANGE_CURRENT_INDEX") {
    return action.index
  }
  return state
}

export const getMedia = (state = [false, []], action) => {
  if (action.type === "GET_MEDIA") {
    return [false, []]
  }
  if (action.type === "SET_MEDIA") {
    action.media.sort((a,b) => {return (a.orderIndex > b.orderIndex) ? 1 : ((b.orderIndex > a.orderIndex) ? -1 : 0);} );
    return [false, action.media]
  }
  if (action.type === "RESET" || action.type === "RESET_SCHEMA") {
    return [false, []]
  }
  return state;
}
