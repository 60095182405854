import {connect} from 'react-redux'
import ManageTexts from '../Components/manageTexts'
import {addTextToDatabase, deleteTextFromDatabase} from '../Fetching/texts'

const mapDispatchToProps = dispatch => {
  return {
    addNewText: (textName) => {addTextToDatabase(dispatch, textName)},
    deleteText: (textName) => {deleteTextFromDatabase(dispatch, textName)}
  }
}

const mapStateToProps = (state) => {
  return {
    texts: state.setTexts[1],
    userId: state.loggedIn[3]
  }
}

const ManageTextsConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageTexts)

export default ManageTextsConnect;
