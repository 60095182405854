import {connect} from 'react-redux'
import {logOut} from '../actions/loginActions'
import {RESET_SCHEMA, RESET_TEXTS,SET_SCHEMA} from '../actions/textActions'
import Dashboard from '../Components/Dashboard'

const mapDispatchToProps = dispatch => {
  return {
    onClickOpenText: (text) => {dispatch(SET_SCHEMA(text))},
    onClickLogOut: () => {dispatch(RESET_TEXTS); dispatch(RESET_SCHEMA); dispatch(logOut()); }
  }
}

const mapStateToProps = (state) => {
  return {
    texts: state.setTexts[1],
    role: state.loggedIn[2],
    userId: state.loggedIn[3],
    textName: state.currentText.textName,

  }
}

const DashboardConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)

export default DashboardConnect;
