export const logIn = (loginResult) =>
{
  return {type:'LOG_IN', isCorrect: loginResult.isCorrect , role: loginResult.role, userId: loginResult.userId}
}

export const logOut = () =>
{
  return {type:'LOG_OUT'}
}

export const requestUserCreds = () =>
{
  return {type: "FETCH_USERDATA_BEGIN"  }
}
