import React from 'react'
import DashboardConnect from '../Connectors/DashboardConnect'

class LogInForm extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line
    this.state = { usernameClass: "", passwordClass: "", registerTokenClass: "", correct: true, mode: "login" }
    this.handleSubmit = this.handleSubmit.bind(this);

    var token = window.localStorage.getItem("lb4Token")
    if (token !== null) {
      this.props.tryLogin(undefined, undefined, this, token);
    }

  }

  componentDidUpdate = () => {
    window.M.updateTextFields();
  }

  handleSubmit(event) {
    event.preventDefault();

    let error = false;
    if (this.state.mode === "login") {
      if (event.target.uname.value === "") {
        error = true;
        this.setState({ usernameClass: "has-error" })
      }
      if (event.target.psw.value === "") {
        error = true;
        this.setState({ passwordClass: "has-error" })
      }

      if (!error) {
        const username = event.target.uname.value;
        const password = event.target.psw.value;
        this.props.tryLogin(username, password, this);
      }
    } else {
      if (event.target.uname.value === "") {
        error = true;
        this.setState({ usernameClass: "has-error" })
      }
      if (event.target.psw.value === "") {
        error = true;
        this.setState({ passwordClass: "has-error" })
      }
      if (event.target.registerToken.value === "") {
        error = true;
        this.setState({ registerTokenClass: "has-error" })
      }

      if (!error) {
        const username = event.target.uname.value;
        const password = event.target.psw.value;
        const registerToken = event.target.registerToken.value;
        this.props.tryRegister(username, password, registerToken);
      }
    }
  }

  getDisplay() {
    if (this.state.mode === "login") {
      return (
        <div className="row">
          <h1 style={{ "textAlign": "center" }}>Login</h1>
          <form method="post" onSubmit={this.handleSubmit}>
            <div className="container form-group">
              <div className={!this.state.correct ? "has-error" : ""}>
                <div className={this.state.usernameClass + " input-field"}>
                  <input id="username" type="text" className="validate" name="uname" placeholder=" " />
                  <label htmlFor="username"><b>Nutzername</b></label>
                  {this.state.usernameClass === "has-error" ? (<div><span className="help-block">Bitte einen Nutzernamen eingeben!</span><br /></div>) : ""}
                </div>
                <div className={this.state.passwordClass}>
                  <div className="input-field">
                    <label className=""><b>Passwort</b></label>
                    <input type="password" className="validate" name="psw" placeholder=" " />
                    {this.state.passwordClass === "has-error" ? (<div><span className="help-block">Bitte ein Passwort eingeben!</span><br /></div>) : ""}
                  </div>
                </div>
                <div className={this.state.correct ? "" : "has-error"}>
                  {!(this.state.correct) ? (<div><span className="help-block">Falscher Benutzername oder Kennwort!</span><br /></div>) : ""}
                </div >
              </div>
              <button id="loginButton" className="btn-large green" type="submit">Login</button>
              <button id="switchMode" className="btn-large right green" onClick={() => { this.setState({ mode: "register" }) }}>Zur Registrierung</button>
            </div>
          </form>
        </div>
      )
    } else {
      return (
        <div className="row">
          <h1 style={{ "text-align": "center" }}>Registrierung</h1>
          <form method="post" onSubmit={this.handleSubmit}>
            <div className="container form-group">
              <div className={this.state.usernameClass}>
                <div className="input-field">
                  <input id="username" type="text" name="uname" placeholder="Nutzername eingeben:" />
                  <label for="username"><b>Nutzername</b></label>
                </div>
                {this.state.usernameClass === "has-error" ? (<div><span className="help-block">Bitte einen Nutzernamen eingeben!</span><br /></div>) : ""}
              </div>
              <div className={this.state.passwordClass}>
                <div className="input-field">
                  <input id="password" type="password" name="psw" placeholder="Passwort eingeben:" />
                  <label for="password"><b>Passwort</b></label>
                </div>
                {this.state.passwordClass === "has-error" ? (<div><span className="help-block">Bitte ein Passwort eingeben!</span><br /></div>) : ""}
              </div>
              <div className={this.state.confirmClass}>
                <div className="input-field">
                  <input id="registerToken" type="text" name="registerToken" placeholder="Registrierungstoken eingeben:" />
                  <label for="registerToken"><b>Registrierungstoken</b></label>
                </div>
                {this.state.registerTokenClass === "has-error" ? (<div><span className="help-block">Bitte einen gültigen Registrierungstoken eingeben!</span><br /></div>) : ""}
              </div>
              <br />
              <button className="btn-large green" type="submit">Registrieren</button>
              <button id="switchMode" className="btn-large right green" onClick={() => { this.setState({ mode: "login" }) }}>Zum Login</button>
            </div>
          </form>
        </div>);
    }

  }

  hideLoginButton = () => {
    setTimeout(() => {
      var button = document.getElementById('loginButton');
      if (button) {
        button.style.display = 'none'
      }
    }, 100);
  }

  showLoginButton = () => {
    setTimeout(() => {
      var button = document.getElementById('loginButton');
      if (button) {
        button.style.display = 'block'
      }
    }, 100);
  }

  render() {
    if (this.props.loggedIn[0]) {
      this.props.getUserTexts()
      return (
        <DashboardConnect />
      )
    }
    return (
      this.getDisplay()
    );
  }

  componentDidMount = () => {

    this.handleIdleTime();

    window.onbeforeunload = () => {
      this.props.onClose(true)
    }
  }

  handleIdleTime = () => {
    var time;
    document.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onmousedown = resetTimer; // touchscreen presses
    document.ontouchstart = resetTimer;
    document.onclick = resetTimer;     // touchpad clicks
    document.onkeydown = resetTimer;   // onkeypress is deprectaed
    document.addEventListener('scroll', resetTimer, true); // improved; see comments
    const this2 = this

    function logout() {
      this2.props.onClose(false)
      setTimeout(() => {
        window.localStorage.removeItem("lb4Token")
        window.location.reload()
      }, 100);

    }

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(logout, 1000 * 60 * 15) 
    }
  };
}



export default LogInForm;
