import React, { Component } from 'react';
import MainViewConnect from '../Connectors/MainViewConnect'
import ManageTextsConnect from '../Connectors/manageTextsConnect'
import ManageClassesConnect from '../Connectors/manageClassesConnect'
import ManageUsersConnect from '../Connectors/manageUsersConnect'
import RegisterFormConnect from '../Connectors/RegisterFormConnect'
import ManageRegisterTokensConnect from '../Connectors/manageRegisterTokensConnect'
import AuthorSupport from './AuthorSupport'

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { currentMode: "overview" }
  }

  getAllTexts() {
    return (
      this.props.texts.map((text, index) => {
        return (
          <li key={text.textName + index}>
            <div onClick={() => this.props.onClickOpenText(text)}>
              <a href="#!" className="collection-item dashBoardTextContainer">
                
                <p className="dashBoardText" ><button style={{marginRight: "15px"}} className="btn-small">Öffnen</button>{text.textName}</p>
                <p style={{marginLeft: "102px"}} className="dashBoardTextSubtitle">{text.subtitle}</p>
              </a>
            </div>
          </li>
        )
      })
    )
  }

  componentDidMount = () => {
    var SideNavelems = document.querySelectorAll('.sidenav');
    window.M.Sidenav.init(SideNavelems, {});
  }

  componentDidUpdate = () => {
    var SideNavelems = document.querySelectorAll('.sidenav');
    window.M.Sidenav.init(SideNavelems, {});
  }

  getNavbar() {
    return (
      <div className="">
        <nav className="nav-wrapper white">
          <div >
            {this.props.role > 0 ? <a href="#!" data-target="mobileSideNav" className="sidenav-trigger dashBoardSideTrigger"><img style={{ "marginTop": "20px" }} src="https://cdn.r2a-lehren-und-lernen.de/assets/menu-24px.svg" alt="menu"></img></a> : ""}
            <ul id="nav-mobile" className="left hide-on-med-and-down dashBoardNavBar">
              <li >{this.props.role > 0 ? <a onClick={() => this.setState({ currentMode: "overview" })} className={this.state.currentMode === "overview" ? "blue active" : "black-text"}>Übersicht</a> : ""}</li>
              <li >{this.props.role > 0 ? <a onClick={() => this.setState({ currentMode: "manageTexts" })} className={this.state.currentMode === "manageTexts" ? "blue active" : "black-text"}>Textverwaltung</a> : ""}</li>
              <li >{this.props.role === 2 ? <a onClick={() => this.setState({ currentMode: "manageClasses" })} className={this.state.currentMode === "manageClasses" ? "blue active" : "black-text"}>Klassen/Gruppenverwaltung</a> : ""}</li>
              <li >{this.props.role === 2 ? <a onClick={() => this.setState({ currentMode: "manageUsers" })} className={this.state.currentMode === "manageUsers" ? "blue active" : "black-text"}>Nutzer bearbeiten</a> : ""}</li>
              <li >{this.props.role === 2 ? <a onClick={() => this.setState({ currentMode: "adduser" })} className={this.state.currentMode === "adduser" ? "blue active" : "black-text"}>Nutzer hinzufügen</a> : ""}</li>
              <li >{this.props.role === 2 ? <a onClick={() => this.setState({ currentMode: "manageRegisterTokens" })} className={this.state.currentMode === "manageRegisterTokens" ? "blue active" : "black-text"}>Reg. Token Verwalten</a> : ""}</li>
              <li >{this.props.role === 2 ? <a onClick={() => this.setState({ currentMode: "authorSupport" })} className={this.state.currentMode === "authorSupport" ? "blue active" : "black-text"}>Autor Support</a> : ""}</li>
            </ul>
            <ul id="nav-mobile" className="right ">
              <a onClick={() => this.props.onClickLogOut()} className="teal hoverable">Abmelden</a>
            </ul>
          </div>
        </nav>

        <ul className="sidenav" id="mobileSideNav">
          <li >{this.props.role > 0 ? <a onClick={() => this.setState({ currentMode: "overview" })} className={this.state.currentMode === "overview" ? "blue active" : "black-text"}>Übersicht</a> : ""}</li>
          <li >{this.props.role > 0 ? <a onClick={() => this.setState({ currentMode: "manageTexts" })} className={this.state.currentMode === "manageTexts" ? "blue active" : "black-text"}>Textverwaltung</a> : ""}</li>
          <li >{this.props.role === 2 ? <a onClick={() => this.setState({ currentMode: "manageClasses" })} className={this.state.currentMode === "manageClasses" ? "blue active" : "black-text"}>Klassen/Gruppenverwaltung</a> : ""}</li>
          <li >{this.props.role === 2 ? <a onClick={() => this.setState({ currentMode: "manageUsers" })} className={this.state.currentMode === "manageUsers" ? "blue active" : "black-text"}>Nutzer bearbeiten</a> : ""}</li>
          <li >{this.props.role === 2 ? <a onClick={() => this.setState({ currentMode: "adduser" })} className={this.state.currentMode === "adduser" ? "blue active" : "black-text"}>Nutzer hinzufügen</a> : ""}</li>
          <li >{this.props.role === 2 ? <a onClick={() => this.setState({ currentMode: "manageRegisterTokens" })} className={this.state.currentMode === "manageRegisterTokens" ? "blue active" : "black-text"}>Reg. Token Verwalten</a> : ""}</li>
          <li >{this.props.role === 2 ? <a onClick={() => this.setState({ currentMode: "authorSupport" })} className={this.state.currentMode === "authorSupport" ? "blue active" : "black-text"}>Autor Support</a> : ""}</li>
        </ul>
      </div>
    )
  }

  render() {
    if (this.props.textName !== "") {
      return (
        <MainViewConnect />
      )
    }

    switch (this.state.currentMode) {
      case "overview":
        return (
          <div>
            {this.getNavbar()}
            <div className="col s8 push-s2" style={{overflowY: "auto", height: "94vh"}}>
              <ul className="collection with-header">
                <li className="collection-header">{this.props.texts.length === 0 ? <h1 className='headLine'>Keine Texte vorhanden</h1> : <h1 className='headLine'>Textauswahl</h1>}</li>
                {this.getAllTexts()}
              </ul>
            </div>
          </div>
        )
      case "manageTexts":
        return (
          <div className="container-fluid">
            {this.getNavbar()}
            <ManageTextsConnect />
          </div>
        )
      case "manageClasses":
        return (
          <div className="container-fluid">
            {this.getNavbar()}
            <ManageClassesConnect />
          </div>
        )
      case "manageUsers":
        return (
          <div className="container-fluid">
            {this.getNavbar()}
            <ManageUsersConnect />
          </div>
        )
      case "adduser":
        return (
          <div className="container-fluid">
            {this.getNavbar()}
            <RegisterFormConnect />
          </div>
        )
      case "manageRegisterTokens":
        return (
          <div className="container-fluid">
            {this.getNavbar()}
            <ManageRegisterTokensConnect />
          </div>
        )
      case "authorSupport":
        return (
          <div className="container-fluid">
            {this.getNavbar()}
            <AuthorSupport />
          </div>
        )
      default:
        return (
          <div>
            {this.getNavbar()}
            <div className="col s8 push-s2" style={{overflowY: "auto", height: "94vh"}}>
              <ul className="collection with-header">
                <li className="collection-header">{this.props.texts.length === 0 ? <h1 className='headLine'>Keine Texte vorhanden</h1> : <h1 className='headLine'><p>Textauswahl</p></h1>}</li>
                {this.getAllTexts()}
              </ul>
            </div>
          </div>
        )
    }
  }
}

export default Dashboard
