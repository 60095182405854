export const getChapters = (state = [false,[]], action) => {
  if (action.type === "GET_CHAPTERS") {
    return [true,state[1]]
  }
  if (action.type === "SET_CHAPTERS") {
    action.chapters.sort((a,b) => {return a.chapterName.toLowerCase().localeCompare(b.chapterName.toLowerCase(), undefined, {numeric: true, sensitivity: 'base'})} );
    return [false,action.chapters]
  }
  if (action.type === "RESET" || action.type === 'RESET_SCHEMA') {
    return [false,[]]
  }
  return state
}

export const quizUrl = (state = "", action) => {
  if (action.type === "SET_QUIZ") {
    if (!action.quizUrl) {
      return '';
    }
    return action.quizUrl
  }

  if (action.type === "RESET") {
    return ""
  }

  return state
}

export const currentChapterIndex = (state = null, action) => {
  if (action.type === "SET_CHAPTER_INDEX") {
    return action.index
  }
  if (action.type === "RESET" || action.type === "RESET_CHAPTER_INDEX") {
    return null
  }

  return state
}
