import React, { Component } from 'react';

let clicked

class ManageQuiz extends Component {
    constructor(props) {
        super(props);
        this.state = { newTextClass: "", selected: "" }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        window.M.updateTextFields()
    }

    componentDidUpdate = () => {
        window.M.updateTextFields();
    }

    handleSubmit(event) {
        event.preventDefault();
        let error = false
        if (clicked === "add") {
            if (event.target.quizUrl.value === "") {
                error = true;
                this.setState({ newTextClass: "has-error" })
            }

            if (!error) {
                if (this.props.quizUrl !== event.target.quizUrl.value) {
                    let qUrl = event.target.quizUrl.value
                    if (!qUrl.includes("&embed=true")) {
                        qUrl = qUrl + "&embed=true"
                    }
                    this.props.addQuiz(this.props.textName, this.props.chapterId, qUrl)
                }
            }
        }
        else {
            this.props.addQuiz(this.props.textName, this.props.chapterId, "")
            event.target.quizUrl.value = ""
        }
    }

    getDisplay() {
        return (
            <div>
                <h1 style={{ "textAlign": "center" }}>Lernkontrollverwaltung</h1>
                <br />
                <form method="post" onSubmit={this.handleSubmit}>
                    <div className="container form-group ">
                        <div className="row">
                            <a className="btn " href="https://forms.office.com/Pages/DesignPage.aspx" target="_blank" rel="noopener noreferrer">Lernkontrolle erstellen</a>
                            {this.props.quizUrl ? <a className="btn right" href={this.props.quizUrl} target="_blank" rel="noopener noreferrer">Lernkontrollvorschau</a> : ""}
                        </div>
                        <div className="row">
                            <div className="input-field col s12">
                                <input placeholder=" " name="quizUrl" id="quizUrl" type="text" className="validate" defaultValue={this.props.quizUrl} />
                                <label htmlFor="quizUrl">Lernkontroll-Url</label>
                            </div>
                        </div>
                        <div className="row">
                            <button className="btn " onClick={() => { clicked = "add" }} type="submit">Lernkontroll-Url festlegen</button>
                            <button className="btn right" onClick={() => { clicked = "delete" }} type="submit">Lernkontroll-Url zurücksetzen</button>
                        </div>
                    </div>
                </form>
                <br />
            </div>
        )
    }

    render() {
        return (
            this.getDisplay()
        );
    }
}

export default ManageQuiz
