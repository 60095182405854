import {combineReducers} from "redux";
import {allClasses} from './classReducers'
import {hiddenSides , currentMode, changeText} from './mainViewReducers';
import {getChapters,currentChapterIndex, quizUrl} from './chapterReducers';
import {getMedia, changeCurrentIndex} from './mediaReducers';
import {setTexts, currentText, classesOfText} from './textReducers'
import {loggedIn} from './loginReducers'
import {chatMessages} from './chatMessages'
import {allUsers} from './userReducers';
import {chatSync} from './chatSyncReducer';
import {glossarTerms} from './glossarReducers';
import {history} from './historyReducer';
import {registerTokens} from './registerTokens'

const rootReducer = combineReducers({
  allClasses,
  allUsers,
  hiddenSides,
  classesOfText,
  currentMode,
  changeText,
  getChapters,
  getMedia,
  changeCurrentIndex,
  currentText,
  setTexts,
  loggedIn,
  chatMessages,
  chatSync,
  currentChapterIndex,
  glossarTerms,
  quizUrl,
  history,
  registerTokens
});

export default rootReducer;
