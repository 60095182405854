import { connect } from 'react-redux'
import MainView from '../Components/MainView'
import { getChaptersFromApi, loadChapterFromApi, deleteChapterFromApi, editChapterOnApi } from '../Fetching/chapters'
import { getMediaFromApi } from '../Fetching/media'
import { LEFT_SIDE, RIGHT_SIDE, TOGGLE_VIEW, RESET_MODE } from '../actions/mainViewActions'
import { CHANGE_TEXT, GET_CHAPTERS, LOAD_CHAPTER, SET_CHAPTER_INDEX, RESET_CHAPTER_INDEX, SET_QUIZ_URL, RESET_CHAPTER_TEXT } from '../actions/chapterActions'
import { logOut } from '../actions/loginActions'
import { GET_MEDIA, TOGGLE_IMAGE_VIEWER, CHANGE_CURRENT_INDEX, RESET_IMAGES, SET_IMAGES } from '../actions/mediaActions'
import { RESET } from '../actions/generalActions'
import { RESET_SCHEMA } from '../actions/textActions'
import { markTextOnApi } from '../Fetching/markText'
import { getAllClassesForText } from '../Fetching/texts'
import { GET_TERMS } from '../actions/glossarActions'
import { getGlossarTermsFromApi } from '../Fetching/glossarTerms'
import { getHistoryEntriesFromApi, endHistoryEntryOnApi, writeHistoryOnApi, endHistoriesForChapterOnApi } from '../Fetching/history'

const mapDispatchToProps = dispatch => {
  return {
    onClickChangeLeft: () => { dispatch(LEFT_SIDE) },
    onClickChangeRight: () => { dispatch(RIGHT_SIDE) },
    onClickChangeMode: (newMode) => { dispatch(TOGGLE_VIEW(newMode)) },
    onSave: (text) => { dispatch(CHANGE_TEXT(text)) },
    onLoad: (textName) => { dispatch(GET_CHAPTERS); getChaptersFromApi(dispatch, textName); getAllClassesForText(dispatch, textName); dispatch(GET_TERMS); getGlossarTermsFromApi(dispatch, textName); },
    onChapterChange: (newChapterId, chapterIndex, chapterName, textName, quizUrl) => { dispatch(LOAD_CHAPTER); dispatch(SET_CHAPTER_INDEX(chapterIndex)); dispatch(SET_QUIZ_URL(quizUrl)); loadChapterFromApi(dispatch, newChapterId, chapterName, textName); dispatch(GET_MEDIA); getMediaFromApi(dispatch, newChapterId, textName) },
    onClickToggleImageViewer: (isOpen, newIndex) => { dispatch(CHANGE_CURRENT_INDEX(newIndex)); dispatch(TOGGLE_IMAGE_VIEWER(isOpen)) },
    onClickChangeIndex: (newIndex) => { dispatch(CHANGE_CURRENT_INDEX(newIndex)) },
    onClickLogOut: () => { dispatch(RESET); dispatch(logOut()); },
    onClickResetSchema: () => { dispatch(RESET_SCHEMA); dispatch(RESET_MODE); dispatch(RESET_IMAGES); dispatch(RESET_CHAPTER_INDEX) },
    onNewPictureAdd: (newImage) => { dispatch(SET_IMAGES(newImage)) },
    resetPictures: () => { dispatch(RESET_IMAGES) },
    onChapterDelete: (chapterId, textName) => { deleteChapterFromApi(dispatch, chapterId, textName) },
    onChapterEdit: (chapterId, chapterName, chapterText, textName, logoUrl, silent, isVisible) => { editChapterOnApi(dispatch, chapterId, chapterName, chapterText, textName, logoUrl, silent, isVisible) },
    onMarkedChanged: (chapterId, markers, textName) => { markTextOnApi(chapterId, markers, textName) },
    onHistoryClicked: (textName) => { getHistoryEntriesFromApi(dispatch, textName) },
    endHistory: (historyActionId, objectId) => { endHistoryEntryOnApi(historyActionId, objectId) },
    clearCurrentChapter: () => { dispatch(RESET_CHAPTER_INDEX); dispatch(RESET_CHAPTER_TEXT) },
    createHistory: (textName, chapterId, historyActionId, objectId) => {writeHistoryOnApi(textName, chapterId, historyActionId, objectId)},
    endHistoriesForChapter: (chapterId) => {endHistoriesForChapterOnApi(chapterId)}
  }
}

const mapStateToProps = (state) => {
  return {
    text: state.changeText,
    logoUrl: state.changeText[4],
    hiddenSides: state.hiddenSides,
    currentMode: state.currentMode,
    data: state.getChapters,
    ip: state.ip,
    media: state.getMedia,
    visible: state.toggleImageViewer,
    currentIndex: state.changeCurrentIndex,
    images: state.setImages,
    role: state.loggedIn[2],
    userId: state.loggedIn[3],
    textName: state.currentText.textName,
    textIsReadOnly: state.currentText.isReadOnly,
    messages: state.chatMessages[0],
    myMessageIds: state.chatMessages[1],
    chatSync: state.chatSync,
    chapterIndex: state.currentChapterIndex,
    glossarTerms: state.glossarTerms,
    quizUrl: state.quizUrl,
    history: state.history,
    valid: state.validToken,
  }
}

const MainViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainView)

export default MainViewConnect;
