import {connect} from 'react-redux'
import ManageClasses from '../Components/manageClasses'
import {addNewClassToDatabase,deleteClassFromDatabase,getAllClasses} from '../Fetching/classes'

const mapDispatchToProps = dispatch => {
  return {
    addNewClass: (className) => {addNewClassToDatabase(className,dispatch)},
    deleteClass: (className) => {deleteClassFromDatabase(className,dispatch)},
    onLoad: () => {getAllClasses(dispatch)}
  }
}

const mapStateToProps = (state) => {
  return {
    classes: state.allClasses
  }
}

const ManageClassesConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageClasses)

export default ManageClassesConnect;
