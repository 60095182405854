import { toast } from 'react-toastify';
import { authenticateAndSendToAPI } from './connection'
import { ADD_HISTORY, SET_HISTORY } from '../actions/historyActions'
import config from '../config';

export const getHistoryEntriesFromApi = (dispatch, textName) => {
  const query = `
  {
    histories(textName: "`+ textName + `")
    {
      chapterName,
      timestamp
      endTimestamp
      duration
      objectName
      historyActionName
    }
  }
  `
  authenticateAndSendToAPI(query, (data) => {
    dispatch(SET_HISTORY(data.histories))
  });
}

export const endHistoryEntryOnApi = (historyActionId, objectId) => {
  const query = `
  mutation
  {
    endHistory(historyActionId: "`+ historyActionId + `" ` + (objectId ? "objectId: " + objectId : "") + `)
  }
  `
  authenticateAndSendToAPI(query, (data) => { });
}

export const endHistoriesForChapterOnApi = (chapterId) => {
  const query = `
  mutation
  {
    endHistoriesForChapter(chapterId: `+ chapterId + `)
  }
  `
  authenticateAndSendToAPI(query, (data) => { });
}

export const endHistoriesOnApi = (isBeacon) => {

  if (isBeacon) {
    navigator.sendBeacon(config.apiUrl + "/api/endHistories",
      {
        token: window.localStorage.getItem('lb4Token')
      })
  } else {
    const xhttp = new XMLHttpRequest();
    xhttp.open("POST", config.apiUrl + "/api/endHistories", true);
    xhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
    xhttp.send(JSON.stringify({ "token":  window.localStorage.getItem('lb4Token'), "isTimeout": true }));
  }

}

export const writeHistoryOnApi = (textName, chapterId, historyActionId, objectId) => {
  const query = `
  mutation
  {
    createHistory(textName: "`+ textName + `" chapterId: ` + chapterId + ` historyActionId: "` + historyActionId + `" ` + (objectId ? "objectId: " + objectId : "") + `)
  }
  `
  authenticateAndSendToAPI(query, (data) => {
  });
}

export const deleteHistoryEntriesForUser = (userName) => {
  const query = `
  mutation
  {
    deleteHistoryForUser(userName: "`+ userName + `")
  }
  `
  authenticateAndSendToAPI(query, (data) => {
    if (data.deleteHistoryForUser === true) {
      toast.success("Der Verlauf wurde erfolgreich gelöscht.")
    } else {
      toast.error("Der Verlauf konnte nicht gelöscht werden.")
    }

  });
}
