import {connect} from 'react-redux'
import ManageUsers from '../Components/manageUsers'
import {getAllUsersInfo,deleteUser, updateUser} from '../Fetching/users'
import {GET_USERS} from '../actions/userActions'
import {getAllClasses} from '../Fetching/classes'

const mapDispatchToProps = dispatch => {
  return {
    onClickDeleteUser: (username) => {deleteUser(username,dispatch)},
    updateUser: (oldName, newName, className, newPassword, role) => {updateUser(dispatch,oldName,newName,className,newPassword, role)},
    onLoad: () => {dispatch(GET_USERS); getAllUsersInfo(dispatch); getAllClasses(dispatch)}
  }
}

const mapStateToProps = (state) => {
  return {
    classes: state.allClasses,
    users: state.allUsers
  }
}

const ManageUsersConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageUsers)

export default ManageUsersConnect;
