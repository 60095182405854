import React from "react";
import { Editor } from "@tinymce/tinymce-react";

class AuthorSupportViewer extends React.Component {


  render() {
    
    return (
      <div
       className={"container col s"+ this.props.columns}
       style={{border: "lightblue solid 2px"}}
       >
        <b>{this.props.userTag}</b>
        <Editor
          value={this.props.text}
          disabled={true}
          inline={true}
          apiKey="zrpdoc8n1fqo04bn3f8cj1rk0c6roro96pew3hvdkm64v8qi"
          init={{
            readonly: 1,
            height: 500,
            menubar: false
          }}
        />
      </div>
    );
  }
}

export default AuthorSupportViewer;
