import AddChapterConnect from "../Connectors/addChapterConnect";
import AddMediaConnect from "../Connectors/manageMediaConnect";
import anchorme from "anchorme";
import TextSettingsConnect from "../Connectors/textSettingsConnect";
import ChatWindow from "./chatWindow";
import React, { Component } from "react";
import TextEditor from "./textEditor";
import TextViewer from "./textViewer";
import DashboardConnect from "../Connectors/DashboardConnect";
import DragResizeContainer from "react-drag-resize";
import HistoryView from "./historyView";
import { Document, Page, pdfjs } from "react-pdf";

import "react-toastify/dist/ReactToastify.css";
import EditGlossarConnect from "../Connectors/editGlossarConnect";
import ManageQuizConnect from "../Connectors/manageQuizConnect";
import { CHAPTER_SCROLLED_DOWN, CHAPTER_SCROLLED_UP, EXTERNAL_MEDIUM_OPENED, GLOSSAR_USED, INTERNAL_MEDIUM_OPENED, MEDIUM_EXPANDED, MEDIUM_RESIZED, NOTEAREA_OPENED } from "../constants/history";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


var $ = require("jquery");
let mediaDidUpdate;
let defaultMediaHeight = (window.innerHeight * 0.75)
var defaultMediaProps = [
  {
    key: "MediaDrag",
    x: (window.innerWidth / 4),
    y: 10,
    width: 600,
    height: defaultMediaHeight,
    zIndex: 10
  }
];

class MainView extends Component {
  constructor(props) {
    super(props);
    this.props.onLoad(this.props.textName);

    mediaDidUpdate = false;
    this.state = {
      chatRunning: false,
      videoUrl: "",
      textCollaps: [],
      mediaDragProps: defaultMediaProps,
      imageUrl: "",
      pdfUrl: "",
      isGlossar: false,
      reload: true,
      currentPageNum: 1,
      totalPageNums: 0,
      mediaId: undefined,
      scrollTicking: false,
      resizeTicking: false,
      currentMode: 'text',
      lastScrollPosition: 0
    };
  }

  componentDidMount = () => {
    let elems = document.querySelectorAll(".dropdown-trigger");
    window.M.Dropdown.init(elems, { hover: false, coverTrigger: false });

    var collapselems = document.querySelectorAll(".collapsible");
    window.M.Collapsible.init(collapselems, {});

    var SideNavelems = document.querySelectorAll(".sidenav");
    window.M.Sidenav.init(SideNavelems, {});
  };

  changeChapter = (newChapterId, chapterIndex, chapterName, textName, quizUrl) => {

    this.props.endHistoriesForChapter(this.props.text[0])
    document.getElementById('textArea').scroll(0, 0)

    setTimeout(() => {
      clearTimeout(this.state.scrollTimeOutId)
      clearTimeout(this.state.resizeTimeOutId)
      this.setState({ scrollTicking: false, lastScrollPosition: 0 })
    }, 100);


    this.props.onChapterChange(
      newChapterId,
      chapterIndex,
      chapterName,
      textName,
      quizUrl)
  }

  componentDidUpdate = () => {
    if (
      this.props.data[1][0] !== undefined &&
      this.props.chapterIndex === null
    ) {
      this.changeChapter(
        this.props.data[1][0].chapterId,
        0,
        this.props.data[1][0].chapterName,
        this.props.textName,
        this.props.data[1][0].quizUrl
      );
    }

    var collapselems = document.querySelectorAll(".collapsible");
    window.M.Collapsible.init(collapselems, {});

    if (mediaDidUpdate) {
      this.onLayoutChange(this.state.mediaDragProps);
      mediaDidUpdate = false;
    }

    if (this.props.currentMode !== "text") {
      this.setChangeListener();
    }
  };

  getGlossarCollection = term => {
    const chapterids = term.chapterIds.split(",");
    return term.versions.map((version, index) => {
      return (
        <a
          href="#!"
          className={
            chapterids[index] === this.props.text[0]
              ? "collection-item active"
              : "collection-item"
          }
          onClick={() => {
            this.changeChapter(
              chapterids[index],
              index,
              term.chapternames[index],
              this.props.textName,
              term.quizUrls[index]
            );
            this.props.createHistory(
              this.props.textName,
              this.props.text[0],
              GLOSSAR_USED,
              term.termId)
            this.setState({
              videoUrl: "",
              imageUrl: "",
              pdfUrl: "",
              mediaDragProps: defaultMediaProps,
              reload: true,
              mediaId: undefined
            });
            this.props.onClickChangeMode("text");
          }}
        >
          {term.chapternames[index]}
        </a>
      );
    });
  };

  changeToFirstChapter = () => {
    if (this.props.data[1].length > 0) {
      let data = this.props.data[1][0];
      this.changeChapter(
        data.chapterId,
        0,
        data.chapterName,
        this.props.textName,
        data.quizUrl
      );
    } else {
      this.props.clearCurrentChapter();
    }

    this.setState({
      videoUrl: "",
      imageUrl: "",
      pdfUrl: "",
      mediaDragProps: defaultMediaProps,
      reload: true,
      mediaId: undefined
    });
    this.props.onClickChangeMode("text");
  };

  getChapterComponents = isLeft => {
    if (this.props.data[1] !== undefined && !this.state.isGlossar) {
      return this.props.data[1].map((data, index) => {
        return (
          <a
            key={"chapterId" + data.chapterId + "onIndex" + index}
            href="#!"
            className={
              data.chapterId === this.props.text[0]
                ? "collection-item active"
                : "collection-item"
            }
            onClick={() => {
              this.changeChapter(
                data.chapterId,
                index,
                data.chapterName,
                this.props.textName,
                data.quizUrl
              );
              this.setState({
                videoUrl: "",
                imageUrl: "",
                pdfUrl: "",
                mediaDragProps: defaultMediaProps,
                reload: true,
                mediaId: undefined
              });
              this.props.onClickChangeMode("text");
            }}
          >
            {data.isVisible ? "" : <i class="material-icons">visibility_off</i>}
           {" " + data.chapterName}
          </a>
        );
      });
    }
    if (this.props.glossarTerms[1] !== undefined && this.state.isGlossar) {
      // eslint-disable-next-line
      return this.props.glossarTerms[1].map((term, index) => {
        if (term.versions.length === 1) {
          return (
            <a
              href="#!"
              className={
                term.chapterIds === this.props.text[0]
                  ? "collection-item active"
                  : "collection-item"
              }
              onClick={() => {
                this.changeChapter(
                  term.chapterIds,
                  index,
                  term.chapternames[0],
                  this.props.textName,
                  term.quizUrls[0]
                );
                this.setState({
                  videoUrl: "",
                  imageUrl: "",
                  pdfUrl: "",
                  mediaDragProps: defaultMediaProps,
                  reload: true,
                  mediaId: undefined
                });
                this.props.onClickChangeMode("text");
              }}
            >
              {term.term}
            </a>
          );
        }
        if (term.versions.length > 1) {
          return (
            <div>
              <ul className="collapsible glossarCollap">
                <li>
                  <div className="collapsible-header">{term.term}</div>
                  <div className="collapsible-body collection collapsible-collection glossarCollap">
                    {this.getGlossarCollection(term)}
                  </div>
                </li>
              </ul>
            </div>
          );
        }
      });
    }
  };

  createHistoryForInternalMedia = (mediaId) => {
    this.props.createHistory(
      this.props.textName,
      this.props.text[0],
      INTERNAL_MEDIUM_OPENED,
      mediaId)
  }

  createHistory = (type, objectId) => {
    this.props.createHistory(
      this.props.textName,
      this.props.text[0],
      type,
      objectId)
  }

  onExternalLinkClicked = (e, mediaId) => {
    if (e.target.nodeName == 'A') {
      this.props.createHistory(
        this.props.textName,
        this.props.text[0],
        EXTERNAL_MEDIUM_OPENED,
        mediaId)
    }
  }

  getInnerMediaComponent = (data, index) => {

    if (data.childData !== "") {
      return (
        <div
          onClick={(e) => { this.onExternalLinkClicked(e, data.mediaId) }}
          dangerouslySetInnerHTML={{
            __html: anchorme(data.childData, {
              attributes: [{ name: "target", value: "_blank" }]
            })
          }}
        ></div>
      )
    }

    if (data.url === "" || !data.url) {
      return ""
    }

    if (data.url.endsWith(".mp4")) {
      return (
        <video
          loop
          autoPlay
          muted
          className="responsive-video"
          onClick={() => {
            this.createHistoryForInternalMedia(data.mediaId)
            this.setState({
              videoUrl: data.url,
              imageUrl: "",
              pdfUrl: "",
              currentPageNum: 1,
              totalPageNums: 1,
              mediaId: data.mediaId
            });
            mediaDidUpdate = true;
          }}
          src={data.url}
          data-target={index}
        />
      )
    }

    if (data.url.endsWith(".pdf")) {
      return (<>
        <a href={data.url} target="_blank" onClick={() => this.createHistoryForInternalMedia(data.mediaId)}>
          In neuem Tab öffnen
        </a>
        <Document
          onClick={() => {
            this.createHistoryForInternalMedia(data.mediaId)
            this.setState({
              pdfUrl: data.url,
              imageUrl: "",
              videoUrl: "",
              currentPageNum: 1,
              totalPageNums: 1,
              mediaId: data.mediaId
            });
            mediaDidUpdate = true;
          }}
          className={"mediaPdfResponsive"}
          file={data.url}
        >
          <Page renderTextLayer={false} pageNumber={1} />
        </Document>
      </>)
    }

    if (data.url.endsWith(".xls") || data.url.endsWith(".xlsx")) {
      return (
        <a href={data.url} target="_blank" onClick={() => this.createHistoryForInternalMedia(data.mediaId)}>
          Excel-Arbeitsmappe herunterladen
        </a>
      )
    }

    if (data.url.endsWith(".doc") || data.url.endsWith(".docx")) {
      return (
        <a href={data.url} target="_blank" onClick={() => this.createHistoryForInternalMedia(data.mediaId)}>
          Word-Dokument herunterladen
        </a>
      )
    }

    return (
      <img
        className="responsive-img"
        onClick={() => {
          this.createHistoryForInternalMedia(data.mediaId)
          this.setState({
            imageUrl: data.url,
            videoUrl: "",
            pdfUrl: "",
            currentPageNum: 1,
            totalPageNums: 1,
            mediaId: data.mediaId
          });
          mediaDidUpdate = true;
        }}
        src={data.url}
        alt={data.displayText}
      />
    )

  }

  getMediaComponents = isLeft => {
    if (!isLeft) {
      if (this.props.media[1] !== undefined) {
        return this.props.media[1].map((data, index) => {
          return (
            <li key={index}>
              <ul className="collapsible">
                <li>
                  <div className="collapsible-header" onClick={(e) => this.onMediaExpandToggle(e, data.mediaId)}>{data.displayText}</div>
                  <div className="collapsible-body mediaText lowPadding">
                    {this.getInnerMediaComponent(data, index)}
                  </div>
                </li>
              </ul>
            </li>
          );
        })
      }
    };
  }

  onMediaExpandToggle = (e, mediaId) => {
    let isOpening = e.target.parentElement.className === "active"
    let textName = this.props.textName
    let chapterId = this.props.text[0]

    if (isOpening) {
      this.props.createHistory(textName, chapterId, MEDIUM_EXPANDED, mediaId)
    } else {
      this.props.endHistory(MEDIUM_EXPANDED, mediaId)
    }
  }

  changeGlossarMode = () => {
    this.setState({ isGlossar: !this.state.isGlossar });
  };

  renderComponents = isLeft => {
    const hidden = isLeft
      ? this.props.hiddenSides[0]
      : this.props.hiddenSides[1];
    if (!hidden) {
      if (isLeft) {
        return (
          <div style={{ height: "100vh" }} className={"col s2 tableOfContents"}>
            <div className="collection">
              <a
                href="#!"
                className="collection-item teal lighten-5"
                onClick={this.changeGlossarMode}
              >
                {this.state.isGlossar
                  ? "Zum Inhaltsverzeichnis"
                  : "Zum Glossar"}
              </a>
              {!this.state.isGlossar && this.checkRights(1) ? (
                <a
                  href="#!"
                  className={
                    "collection-item teal lighten-" +
                    (this.props.currentMode === "addChapter" ? "4" : "5")
                  }
                  onClick={() => {
                    this.props.onClickChangeMode("addChapter");
                    this.resetMediaUrls();
                  }}
                >
                  + Kapitel hinzufügen
                </a>
              ) : (
                undefined
              )}
            </div>
            <div className="collection">
              {this.getChapterComponents(isLeft)}
            </div>
          </div>
        );
      } else {
        return (
          <div style={{ height: "100vh" }} className={"col s2 mediaArea"}>
            <ul className="nav compList">{this.getMediaComponents(isLeft)}</ul>
          </div>
        );
      }
    }
  };

  loadLogo(content, editor) {
    return new Promise((resolve, reject) => {
      $("#logo").on("load", e => {
        $("#logo").off("load");
        resolve();
      });

      $("#logo").on("error", e => {
        $("#logo").off("error");
        reject();
      });
    });
  }

  onPrintClicked(editor) {
    var isRightHidden = this.props.hiddenSides[1];
    var isLeftHidden = this.props.hiddenSides[0];

    if (!isRightHidden) {
      this.props.onClickChangeRight();
      window.sessionStorage.setItem("right", true + "");
    } else {
      window.sessionStorage.setItem("right", false + "");
    }

    if (!isLeftHidden) {
      this.props.onClickChangeLeft();
      window.sessionStorage.setItem("left", true + "");
    } else {
      window.sessionStorage.setItem("left", false + "");
    }

    function showItem($node) {
      // remove the 'hide' class. This is moot
      // until we loop to the top level node, at
      // which point this takes affect
      $node.removeClass("doNotPrint");
      // we don't want to print the siblings
      // so we will hide those
      $node.siblings().addClass("doNotPrint");
      // now we check to see if this item has a parent
      // and, if so...
      if ($node.parent().length) {
        // ...we want to show the parent, hide its
        // siblings, and then continue this process
        // back to the top of the node tree
        showItem($node.parent());
      }
    }
    showItem($("#viewer"));

    setTimeout(() => {
      editor.execCommand("mcePrint");

      if (window.sessionStorage.getItem("right") === "true") {
        this.props.onClickChangeRight();
      }

      if (window.sessionStorage.getItem("left") === "true") {
        this.props.onClickChangeLeft();
      }
    }, 42);
  }

  checkRights(minRights) {
    return (
      (this.props.role === minRights && !this.props.textIsReadOnly) ||
      this.props.role === 2
    );
  }

  getButtons = isSideNav => {
    return (
      <div>
        {isSideNav ? (
          <div>
            <li>
              <a
                onClick={() => {
                  this.props.onClickChangeMode("text");
                  this.resetMediaUrls();
                  if (this.props.data[1].length > 0) {
                    this.changeChapter(
                      this.props.text[0],
                      0,
                      this.props.text[2],
                      this.props.textName,
                      this.props.quizUrl
                    );
                  }
                }}
                className={
                  this.props.currentMode === "text"
                    ? "blue white-text"
                    : " black-text"
                }
              >
                Lese-Ansicht
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  if (this.props.data[1].length > 0) {
                    this.props.onClickChangeMode("editor");
                    this.resetMediaUrls();
                  }
                }}
                className={
                  this.props.currentMode === "editor"
                    ? "blue white-text"
                    : " black-text"
                }
              >
                Editor
              </a>
            </li>
          </div>
        ) : (
          <li>
            {this.checkRights(1) ? (
              <a
                className={
                  "dropdown-trigger " +
                  (this.props.currentMode === "text" ||
                    this.props.currentMode === "editor"
                    ? "teal white-text"
                    : "black-text")
                }
                href="#!"
                data-target="ansicht"
              >
                Ansicht
              </a>
            ) : (
              ""
            )}
          </li>
        )}
        <li>
          <a
            onClick={() => {
              this.props.onClickChangeMode("addMedia");
              this.resetMediaUrls();
            }}
            className={
              this.props.currentMode === "addMedia"
                ? "blue white-text"
                : " black-text"
            }
          >
            Medien verwalten
          </a>
        </li>
        <li>
          <a
            onClick={() => {
              this.props.onClickChangeMode("textSettings");
              this.resetMediaUrls();
            }}
            className={
              this.props.currentMode === "textSettings"
                ? "blue white-text"
                : " black-text"
            }
          >
            Texteinstellungen
          </a>
        </li>
        <li>
          <a
            onClick={() => {
              this.props.onClickChangeMode("editGlossar");
              this.resetMediaUrls();
            }}
            className={
              this.props.currentMode === "editGlossar"
                ? "blue white-text"
                : " black-text"
            }
          >
            Glossar bearbeiten
          </a>
        </li>
        <li>
          <a
            onClick={() => {
              this.props.onClickChangeMode("manageQuiz");
              this.resetMediaUrls();
            }}
            className={
              this.props.currentMode === "manageQuiz"
                ? "blue white-text"
                : " black-text"
            }
          >
            Lernkontrolle bearbeiten
          </a>
        </li>
      </div>
    );
  };

  renderSettings = role => {
    return (
      <div>
        {this.checkRights(1) ? (
          <ul id="ansicht" className="dropdown-content">
            <li>
              <a
                onClick={() => {
                  this.props.onClickChangeMode("text");
                  this.resetMediaUrls();
                  if (this.props.data[1].length > 0) {
                    this.changeChapter(
                      this.props.text[0],
                      0,
                      this.props.text[2],
                      this.props.textName,
                      this.props.quizUrl
                    );
                  }
                }}
                className={
                  this.props.currentMode === "text"
                    ? "blue white-text"
                    : " black-text"
                }
              >
                Lese-Ansicht
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  if (this.props.data[1].length > 0) {
                    this.props.onClickChangeMode("editor");
                    this.resetMediaUrls();
                  }
                }}
                className={
                  this.props.currentMode === "editor"
                    ? "blue white-text"
                    : " black-text"
                }
              >
                Editor
              </a>
            </li>
          </ul>
        ) : (
          ""
        )}

        <ul className="hide-on-med-and-down mainViewHideOnTablet">
          <li>
            <a
              onClick={() => this.props.onClickChangeLeft()}
              className={
                this.props.hiddenSides[0]
                  ? "red black-text"
                  : "green white-text"
              }
            >
              {this.props.hiddenSides[0] ? "→" : "←"}
            </a>
          </li>
          {this.checkRights(1) ? this.getButtons(false) : ""}
          <li>
            <a
              onClick={() => this.props.onClickChangeMode("showHistory")}
              className={
                this.props.currentMode === "showHistory"
                  ? "blue active"
                  : "black-text"
              }
            >
              Verlauf
            </a>
          </li>
          {this.props.quizUrl != "" ?
            <li>
              <a
                onClick={() => this.props.onClickChangeMode("quiz")}
                className={
                  this.props.currentMode === "quiz"
                    ? "blue active"
                    : "black-text"
                }
              >
                Lernkontrolle
              </a>
            </li>
            : ""}
        </ul>
        <ul className="right ">
          <li>
            <a
              onClick={() => {
                this.props.onClickResetSchema();
                this.resetMediaUrls();
              }}
              className="teal mainViewHideOnTablet"
            >
              Zurück zur Übersicht
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                this.props.onClickLogOut();
                this.resetMediaUrls();
              }}
              id="logout"
              className="red mainViewHideOnTablet"
            >
              Abmelden
            </a>
          </li>
          <li>
            <a
              onClick={() => this.props.onClickChangeRight()}
              className={
                "hide-on-med-and-down mainViewHideOnTablet " +
                (this.props.hiddenSides[1]
                  ? "red black-text"
                  : "green white-text")
              }
            >
              {this.props.hiddenSides[1] ? "←" : "→"}
            </a>
          </li>
        </ul>
        <ul className="sidenav " id="mobileSideNav">
          <li>
            <a
              onClick={() => this.props.onClickChangeLeft()}
              className={
                this.props.hiddenSides[0]
                  ? "red black-text"
                  : "green white-text"
              }
            >
              {this.props.hiddenSides[0] ? "→" : "←"}
            </a>
          </li>
          {this.checkRights(1) ? this.getButtons(true) : ""}
          <li>
            <a
              onClick={() => this.props.onClickChangeMode("showHistory")}
              className={
                this.props.currentMode === "showHistory"
                  ? "blue active"
                  : "black-text"
              }
            >
              Verlauf
            </a>
          </li>
          {this.props.quizUrl != "" ?
            <li>
              <a
                onClick={() => this.props.onClickChangeMode("quiz")}
                className={
                  this.props.currentMode === "quiz"
                    ? "blue active"
                    : "black-text"
                }
              >
                Lernkontrolle
              </a>
            </li>
            : ""}
          <li>
            <a
              onClick={() => {
                this.props.onClickResetSchema();
                this.resetMediaUrls();
              }}
              className="teal"
            >
              Zurück zur Übersicht
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                this.props.onClickLogOut();
                this.resetMediaUrls();
              }}
              className="red"
            >
              Abmelden
            </a>
          </li>
          <li>
            <a
              onClick={() => this.props.onClickChangeRight()}
              className={
                this.props.hiddenSides[1]
                  ? "red black-text"
                  : "green white-text"
              }
            >
              {this.props.hiddenSides[1] ? "←" : "→"}
            </a>
          </li>
        </ul>
      </div>
    );
  };

  renderView = currentMode => {
    switch (currentMode) {
      case "text":
        return (
          <TextViewer
            onPrintClicked={editor => this.onPrintClicked(editor)}
            onMarkedChanged={(chapterId, markers) =>
              this.props.onMarkedChanged(
                chapterId,
                markers,
                this.props.textName
              )
            }
            logoUrl={this.props.logoUrl}
            text={this.props.text}
            version={this.props.text[3]}
            endHistoriesForChapter={() => this.props.endHistoriesForChapter(this.props.text[0])}
          />
        );
      case "editor":
        return (
          <TextEditor
            changeToFirstChapter={this.changeToFirstChapter}
            text={this.props.text}
            onChapterDelete={this.props.onChapterDelete}
            onChapterEdit={this.props.onChapterEdit}
            logoUrl={this.props.logoUrl}
            textName={this.props.textName}
            imageAlign={true}
          />
        );
      case "addMedia":
        return <AddMediaConnect />;
      case "addChapter":
        return <AddChapterConnect />;
      case "textSettings":
        return <TextSettingsConnect />;
      case "editGlossar":
        return <EditGlossarConnect />;
      case "manageQuiz":
        return <ManageQuizConnect />;
      case "quiz":
        return (
          <iframe
            title="Lernkontrolle"
            src={this.props.quizUrl}
            style={{
              border: "none",
              maxWidth: "100%",
              maxHeight: "100vh",
              width: "100%",
              height: "95%"
            }}
            allowfullscreen
            webkitallowfullscreen
            mozallowfullscreen
            msallowfullscreen
          >
            {" "}
          </iframe>
        );
      case "showHistory":
        return (
          <HistoryView
            history={this.props.history}
            onLoad={() => this.props.onHistoryClicked(this.props.textName)}
          />
        );
      default:
        return (
          <TextViewer
            onPrintClicked={editor => this.onPrintClicked(editor)}
            onMarkedChanged={(chapterId, markers) =>
              this.props.onMarkedChanged(
                chapterId,
                markers,
                this.props.textName
              )
            }
            logoUrl={this.props.logoUrl}
            text={this.props.text}
            endHistoriesForChapter={() => this.props.endHistoriesForChapter(this.props.text[0])}
          />
        );
    }
  };

  setChangeListener(div, listener) {
    $(div).off();
    $(div).on("keydown", listener);
    $(div).on("keypress", listener);
    $(div).on("paste", listener);
    $(div).on("cut", listener);
    $(div).on("delete", listener);
  }

  addViewListener = mode => {
    const elementList = document.getElementsByClassName(
      "public-DraftEditor-content"
    );
    if (mode === "editor") {
      $(elementList[0]).off();
    } else {
      if (elementList[0] === undefined) {
        return;
      }
      this.setChangeListener(elementList[0], e => {
        e.stopPropagation();
        e.preventDefault();
        e.returnValue = false;
        e.cancelBubble = true;
        return false;
      });
    }
  };

  renderChat = () => {
    if (this.props.text[0] === 0) {
      return undefined;
    }
    return (
      <ChatWindow
        openImage={newImageUrl => {
          this.setState({ imageUrl: newImageUrl, pdfUrl: "", videoUrl: "" });
          mediaDidUpdate = true;
        }}
        openPdf={newPdfUrl => {
          this.setState({ imageUrl: "", pdfUrl: newPdfUrl, videoUrl: "", currentPageNum: 1, totalPageNums: 1 });
          mediaDidUpdate = true;
        }}
        chapterId={this.props.text[0]}
        userId={this.props.userId}
        textName={this.props.textName}
        chapterName={this.props.text[2]}
        onChatOpened={this.onChatOpened}
        onChatClosed={this.onChatClosed}
      />
    );
  };

  onChatOpened = () => {
    this.props.createHistory(this.props.textName, this.props.text[0], NOTEAREA_OPENED)
  }
  onChatClosed = () => {
    this.props.endHistory(NOTEAREA_OPENED)
  }

  onLayoutChange = (currentDragProps, e) => {
    let widthChange = currentDragProps[0].width !== this.state.mediaDragProps[0].width
    let heightChange = currentDragProps[0].height !== this.state.mediaDragProps[0].height
    let wasResized = widthChange || heightChange

    if (wasResized) {
      this.onMediaResized(this.state.mediaId)
    }

    var child = document.getElementById(this.state.mediaDragProps[0].key);
    if (
      child !== undefined &&
      child !== null &&
      child.clientHeight !== undefined
    ) {
      currentDragProps[0].width = child.clientWidth + 2;
      currentDragProps[0].height = child.clientHeight + 2;
    }

    let tooMuch = currentDragProps[0].height - (window.innerHeight - 30)
    if (tooMuch > 0) {
      let ratio = currentDragProps[0].height / currentDragProps[0].width
      let newWidth = currentDragProps[0].width - (tooMuch * ratio)
      currentDragProps[0].width = newWidth
    }

    this.setState({ mediaDragProps: currentDragProps });

    if (tooMuch > 0) {
      setTimeout(() => {
        this.onLayoutChange(currentDragProps)
      }, 100);
    }
  };

  canResizable = () => {
    return {
      top: false,
      right: false,
      bottom: true,
      left: false,
      topRight: true,
      bottomRight: true,
      bottomLeft: true,
      topLeft: true
    };
  };

  changePage = changeNumber => {
    let newPageNum = this.state.currentPageNum + changeNumber;
    if (newPageNum > 0 && newPageNum <= this.state.totalPageNums) {
      this.setState({ currentPageNum: newPageNum });
    }
  };

  onDocumentLoadSuccess = props => {
    this.setState({ totalPageNums: props.numPages }, () => {
      setTimeout(() => {
        this.onLayoutChange(
          this.state.mediaDragProps
        );
      }, 100);
    });
  };

  getContentForResizable = key => {
    const changeLayout = () => {
      if (this.state.reload) {
        this.onLayoutChange(this.state.mediaDragProps);
        this.setState({ reload: false });
      }
    };
    if (this.state.imageUrl !== "") {
      return (
        <img
          id={key}
          className="noDrag"
          style={{ height: "100%", width: "auto" }}
          src={this.state.imageUrl}
          alt="Medium"
        />
      );
    }
    if (this.state.pdfUrl !== "") {
      return (
        <div id={key}>
          <Document
            file={this.state.pdfUrl}
            onLoadSuccess={this.onDocumentLoadSuccess}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          >
            <Page
              renderTextLayer={true}
              pageNumber={this.state.currentPageNum}
              renderAnnotationLayer={false}
            />
          </Document>
          <div className="center-align">
            <button
              className="btn"
              onClick={() => {
                this.changePage(-1);
              }}
            >
              &lt;
            </button>
            Seite {this.state.currentPageNum} von {this.state.totalPageNums}
            <button
              className="btn"
              onClick={() => {
                this.changePage(1);
              }}
            >
              &gt;
            </button>
          </div>
        </div>
      );
    }

    if (this.state.videoUrl !== "") {
      return (
        <video
          controls
          controlsList="nodownload"
          id={key}
          className="noDrag responsive-video"
          onLoadedMetadata={changeLayout}
          style={{ height: "auto", width: "100%", display: "flex" }}
        >
          <source src={this.state.videoUrl} type="video/mp4" />
        </video>
      );
    }
  };

  resetMediaUrls = () => {
    this.setState({
      videoUrl: "",
      imageUrl: "",
      pdfUrl: "",
      currentPageNum: 1,
      totalPageNums: 1,
      mediaId: undefined
    });
  };

  onTextAreaScrolled = (e) => {
    if (this.state.currentMode != 'text') {
      return
    }

    if (!this.state.scrollTicking) {
      let lastPosition = document.getElementById('textArea').scrollTop
      let scrollTimeOutId = setTimeout(() => {
        let isUp = document.getElementById('textArea').scrollTop < lastPosition
        this.createHistory(isUp ? CHAPTER_SCROLLED_UP : CHAPTER_SCROLLED_DOWN)
        this.setState({ scrollTicking: false })
      }, 500);
      this.setState({ scrollTicking: true, scrollTimeOutId, lastScrollPosition: lastPosition })
    }
  }

  onMediaResized = (mediaId) => {
    if (this.state.currentMode != 'text') {
      return
    }

    if (!this.state.resizeTicking) {
      let resizeTimeOutId = setTimeout(() => {
        this.createHistory(MEDIUM_RESIZED, mediaId)
        this.setState({ resizeTicking: false })
      }, 500);
      this.setState({ resizeTicking: true, resizeTimeOutId})
    }
  }

  getResizableMedia = () => {
    if (
      this.state.imageUrl !== "" ||
      this.state.videoUrl !== "" ||
      this.state.pdfUrl !== ""
    ) {
      return (
        <DragResizeContainer
          id="mediaDrag"
          className="resize-container"
          resizeProps={{
            minWidth: 50,
            minHeight: 50,
            enable: this.canResizable()
          }}
          onLayoutChange={this.onLayoutChange}
          layout={this.state.mediaDragProps}
          dragProps={{ disabled: false }}
          scale={1}
        >
          {this.state.mediaDragProps.map(single => {
            return (
              <div
                key={single.key}
                className="child-container size-auto border content "
              >
                <img
                  className="img noDrag"
                  style={{ zIndex: "1337" }}
                  src="https://cdn.r2a-lehren-und-lernen.de/assets/CrossNew.png "
                  alt="Schließen"
                  onClick={() => {
                    this.props.endHistory(INTERNAL_MEDIUM_OPENED, this.state.mediaId)
                    this.resetMediaUrls();
                    this.setState({
                      mediaDragProps: defaultMediaProps,
                      reload: true
                    });
                  }}
                />
                {this.getContentForResizable(single.key)}
              </div>
            );
          })}
        </DragResizeContainer>
      );
    }
  };
  render() {
    if (this.props.textName === "") {
      return (
        <div>
          <DashboardConnect />
        </div>
      );
    }
    const size =
      8 +
      (this.props.hiddenSides[0] ? 2 : 0) +
      (this.props.hiddenSides[1] ? 2 : 0);

    return (
      <div>
        <nav className="nav-wrapper white">
          <div>
            <a
              href="#!"
              data-target="mobileSideNav"
              className="sidenav-trigger mainViewSideTrigger"
            >
              <img
                style={{ marginTop: "20px" }}
                src="https://cdn.r2a-lehren-und-lernen.de/assets/menu-24px.svg"
                alt="menu"
              ></img>
            </a>
            {this.renderSettings(this.props.role, this.props.textIsReadOnly)}
          </div>
        </nav>

        <div className="row">
          {this.getResizableMedia()}
          {this.renderComponents(true)}

          <div id="textArea" className={"textArea col s" + size} onScroll={this.onTextAreaScrolled}>
            {this.renderView(this.props.currentMode)}
          </div>
          {this.addViewListener(this.props.currentMode)}
          {this.renderComponents(false)}
        </div>
        {this.renderChat()}
      </div>
    );
  }
}

export default MainView;
