export const SET_TEXTS = (texts) => {
  return {type: "SET_TEXTS" , newTexts: texts}
}

export const GET_TEXTS = {type: "GET_TEXTS"}

export const RESET_TEXTS = {type: "RESET_TEXTS"}

export const SET_SCHEMA = (schema) => {
  return {type: "SET_SCHEMA", newSchema: schema}
}

export const RESET_SCHEMA = {type: "RESET_SCHEMA"}

export const GET_TEXT_CLASSES = {type: "GET_TEXT_CLASSES"}

export const SET_TEXT_CLASSES = (allClasses) => {
  return {type: "SET_TEXT_CLASSES", classes: allClasses}
}
