import {tryRegister} from '../Fetching/login'
import RegisterForm from '../Components/RegisterForm';
import { connect } from 'react-redux'
import {getAllClasses} from '../Fetching/classes'

const mapDispatchToProps = (dispatch) => {
  return {
    tryToRegister: (username, password, className) => {tryRegister(username, password,className, dispatch)},
    onLoad: () => {getAllClasses(dispatch)}
  }
}

const mapStateToProps = (state) => {
  return {
    classes: state.allClasses
  }
}

const RegisterFormConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterForm)

export default RegisterFormConnect;
