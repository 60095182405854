export const getRandomNoCacheString = () => {
    return (
      "?noCache=" +
      Math.floor(Math.random() * Math.floor(1000)) +
      "" +
      Math.floor(Math.random() * Math.floor(5000))
    );
  };

export const sanatizeString = (string) => {
  return string.replace(/\r?\n|\r/g, "").replace(/\\/g, "\\\\").replace(/"/g, '\\\"')
}