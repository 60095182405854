import React, { Component } from "react";
import Select from "react-select";

import { toast } from "react-toastify";

import "../react-select.css";

let clicked;

class TextSettings extends Component {
  constructor(props) {
    super(props);
    this.props.onLoad(this.props.textName);
    this.state = { newTextClass: "", selected: this.props.textClasses[1].map((a) => {return {value: a, label: a}}) };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    if (clicked === "update") {
      this.props.updateClassesForText(
        this.props.textName,
        this.state.selected.map((data, index) => {
          return data.value;
        })
      );
    }

    if (clicked === "rename") {
      if (event.target.newTextName.value !== null) {
        this.props.renameText(
          this.props.textName,
          event.target.newTextName.value,
          event.target.newSubtitle.value
        );
      } else {
        toast.error("Bitte einen neuen Namen angeben!");
      }
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      options: newProps.classes[1].map(data => {
        return { value: data, label: data };
      })
    });
  }

  getSelect(classes) {}

  getDisplay(style) {
    return (
      <div>
        <h1 style={{ textAlign: "center" }}>Texteinstellungen</h1>
        <br />
        <form method="post" onSubmit={this.handleSubmit}>
          <div className="container form-group ">
            <div className={this.state.mediaNameClass}>
              <label className="control-label">
                <b>Textname verändern: </b>
              </label>
              <br />
              <input
                type="text"
                className="fullInput form-control"
                placeholder=" "
                name="newTextName"
                id="newTextName"
                defaultValue={this.props.textName}
              />
              <label className="control-label">
                <b>Untertitel verändern: </b>
              </label>
              <br />
              <input
                type="text"
                className="fullInput form-control"
                placeholder=" "
                name="newSubtitle"
                id="newSubtitle"
                defaultValue={this.props.subtitle}
              />
              <button
                className="btn "
                onClick={() => {
                  clicked = "rename";
                }}
                type="submit"
              >
                Ändern
              </button>
            </div>
            <br />
            <br />
            <label className="control-label">
              <b>Text für folgende Klassen/Gruppen freigeben:</b>
            </label>
            <Select
              name="form-field-name"
              isMulti={true}
              value={this.state.selected}
              options={this.state.options}
              noResultsText="Es wurden keine Klassen gefunden"
              placeholder="Klassen auswählen"
              onChange={value => {
                if (value === null) 
                {
                  value = []
                }
                this.setState({ selected: value });
              }}
            />
            <br />
            <button
              className="btn "
              onClick={() => {
                clicked = "update";
              }}
              type="submit"
            >
              Ausgewählte Klassen übernehmen
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {    
    const style = {
      display: "-webkit-flex",
      WebkitFlex: "0 1 auto",
      flex: "0 1 auto",
      WebkitFlexDirection: "column",
      flexDirection: "column",
      WebkitFlexGrow: 1,
      flexGrow: 1,
      WebkitFlexShrink: 0,
      flexShrink: 0,
      WebkitFlexBasis: "25%",
      flexBasis: "25%",
      maxWidth: "25%",
      height: "200px",
      WebkitAlignItems: "center",
      alignItems: "center",
      WebkitJustifyContent: "center",
      justifyContent: "center"
    };
    return this.getDisplay(style);
  }
}

export default TextSettings;
