import {connect} from 'react-redux'
import editGlossar from '../Components/editGlossar'
import {addGlossarTermToApi,editGlossarTermOnApi,deleteGlossarTermFromApi} from '../Fetching/glossarTerms'

const mapDispatchToProps = dispatch => {
  return {
    addGlossarTerm: (textName, chapterObjs,term) => {addGlossarTermToApi(dispatch,chapterObjs, textName,term)},
    editGlossarTerm: (textName, chapterObjs,term,termId) => {editGlossarTermOnApi(dispatch,chapterObjs, textName,term,termId)},
    deleteGlossarTerm: (textName, termId) => {deleteGlossarTermFromApi(dispatch,termId,textName)}
  }
}

const mapStateToProps = (state) => {
  return {
    glossarTerms: state.glossarTerms,
    textName: state.currentText.textName,
    chapters: state.getChapters
  }
}

const EditGlossarConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(editGlossar)

export default EditGlossarConnect;
