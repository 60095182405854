import { connect } from 'react-redux'
import ManageMedia from '../Components/manageMedia'
import { addMediaToChapter, deleteMediaFromChapter , editMediaNameOnAPI, getMediaFromApi,editMediaOrder} from '../Fetching/media'

const mapDispatchToProps = dispatch => {
  return {
    onMediaAdd: (chapterId, displayText, url, childData, textName) => { addMediaToChapter(dispatch, chapterId, displayText, url, childData, textName) },
    onMediaDelete: (mediaId, chapterId, textName) => { deleteMediaFromChapter(dispatch, mediaId, chapterId, textName) },
    onMediaEdited: (chapterId,displayText,mediaId,textName) => {editMediaNameOnAPI(chapterId,displayText,mediaId,textName,dispatch)},
    refreshMedia: (chapterId, textName) => {getMediaFromApi(dispatch, chapterId, textName)},
    editMediaOrder: (media, chapterId, textName) => {editMediaOrder(dispatch,media,chapterId,textName)}
  }
}

const mapStateToProps = (state) => {
  return {
    chapterId: state.changeText[0],
    media: state.getMedia,
    textName: state.currentText.textName,
    result: state.imageUpload
  }
}

const ManageMediaConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageMedia)

export default ManageMediaConnect
