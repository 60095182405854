import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MessageList from './MessageList';
import UserInput from './UserInput';
import Header from './Header';
import { toast } from 'react-toastify';
import config from '../../config'
const axios = require('axios');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

const onDropHandler = (event,ws,chapterId,textName) => {
  event.preventDefault();

  let file = event.dataTransfer.items[0].getAsFile()

  if (file && file.type.startsWith("image")) {
    let mydata = new FormData();
    mydata.append('File', file);
    mydata.append("textName", textName);
    mydata.append("chapterId", chapterId)
    mydata.append("uploadType", "Chat")

    axios.request({
      url: config.apiUrl+ "/api/upload",
      method: 'POST',
      data: mydata
    }).then((response) => {
      if (response.status === 201) {
        ws.send("uploadImage┐"+response.data.Url+"┐"+file.name)
      } else {
        response.json().then((response) => { toast.error(response.Message)})
      }
    }).catch((error) => {
      toast.error(error.message)
    });
  }

  if (event.dataTransfer.items) {
    event.dataTransfer.items.clear();
  } else {
    event.dataTransfer.clearData();
  }
}

var ChatWindow = function (_Component) {
  _inherits(ChatWindow, _Component);

  function ChatWindow(props) {
    _classCallCheck(this, ChatWindow);

    return _possibleConstructorReturn(this, _Component.call(this, props));
  }

  ChatWindow.prototype.onUserInputSubmit = function onUserInputSubmit(message) {
    this.props.onUserInputSubmit(message);
  };

  

  ChatWindow.prototype.onDragOverHandler = (ev) => {
    ev.preventDefault();
  }

  ChatWindow.prototype.onMessageReceived = function onMessageReceived(message) {
    this.setState({ messages: [].concat(this.state.messages, [message]) });
  };

  ChatWindow.prototype.render = function render() {
    var messageList = this.props.messageList || [];
    var classList = ["sc-chat-window", this.props.isOpen ? "opened" : "closed"];
    return <div className={classList.join(' ')} onDrop={(event) => {onDropHandler(event, this.props.ws, this.props.chapterId, this.props.textName)}} onDragOver={this.onDragOverHandler}>
    {
      React.createElement(Header, {
        teamName: this.props.agentProfile.teamName,
        imageUrl: this.props.agentProfile.imageUrl,
        onClose: this.props.onClose
      })}
      {React.createElement(MessageList, {
        openImage: this.props.openImage,
        openPdf: this.props.openPdf,
        messages: messageList,
        imageUrl: this.props.agentProfile.imageUrl,
        ws : this.props.ws
      })}
      {React.createElement(UserInput, { ws: this.props.ws, chapterId: this.props.chapterId, textName: this.props.textName, showEmoji: this.props.showEmoji, onSubmit: this.onUserInputSubmit.bind(this) })
      }</div>
  };

  return ChatWindow;
}(Component);

ChatWindow.propTypes = process.env.NODE_ENV !== "production" ? {
  showEmoji: PropTypes.bool
} : {};

export default ChatWindow;