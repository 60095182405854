export default [
    "BFEDD2", "Light Green",
    "FBEEB8", "Light Yellow",
    "F8CAC6", "Light Red",
    "ECCAFA", "Light Purple",
    "C2E0F4", "Light Blue",
    "2DC26B", "Green",
    "FFFF00", "Yellow",
    "E03E2D", "Red",
    "B96AD9", "Purple",
    "3598DB", "Blue",
    "169179", "Dark Turquoise",
    "E67E23", "Orange",
    "BA372A", "Dark Red",
    "843FA1", "Dark Purple",
    "236FA1", "Dark Blue",
    "ECF0F1", "Light Gray",
    "CED4D9", "Medium Gray",
    "95A5A6", "Gray",
    "7E8C8D", "Dark Gray",
    "34495E", "Navy Blue",
    "000000", "Black",
    "ffffff", "White",
  ]