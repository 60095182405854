import {deleteTokenFromApi} from "../Fetching/tokens";
export const loggedIn = (state = [false,false,0,-1], action) =>
{
switch (action.type) {
  case 'LOG_IN':
      if (action.isCorrect) {
        return [true,false,action.role,action.userId]
      }
      return [false, false, 0,-1]
   case 'LOG_OUT':
   deleteTokenFromApi();
    window.localStorage.clear()
    return [false,false,0,-1];
   case 'FETCH_USERDATA_BEGIN':
    return [state[0],true, 0 ,state[3]];
  default:
    return state;
  }

}
