import React, { Component } from 'react'
import { Launcher } from '../chatWindow/index'
import { toast } from 'react-toastify';
import config from '../config';
const axios = require('axios');

class ChatWindow extends Component {
  constructor(props) {
    super(props);   
    this.state = { messages: [], ws: undefined, chapterId: props.chapterId }

    this.connectToWs()
  }

  connectToWs = () => {
    var token = window.localStorage.getItem("lb4Token")
    const wsConnection = new WebSocket(config.webSocketUrl+'/api/chat?token=' + token + '&textName=' + this.props.textName + '&chapterId=' + this.props.chapterId);

    wsConnection.onclose = () => {
      this.setState({
        messages: [{
          author: "them",
          type: "disconnected",
          chatWindow: this,
          data: {
            text: "Der Noitzbereich hat die Verbindung verloren."
          }
        }]
      })
    }

    wsConnection.onmessage = (message) => {
      try {
        var json = JSON.parse(message.data);
        let allChatMessages = []
        for (var i = 0; i < json.length; i++) {          
          allChatMessages.push(
            {
              author: json[i].userId === this.props.userId ? 'me' : 'them',
              type: 'text',
              id: json[i].chatMessageId,
              data: {
                text: json[i].chatDate + " <br/>" + json[i].userName + ":<br/>" + json[i].chatMessage
              }
            }
          )
        }

        this.setState({ messages: allChatMessages, ws: wsConnection })
      } catch(e) {
        toast.error("Falsche antwort vom Notizbereich Server")
      }
    };
  }

  sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  onMessageSent(message) {
    if (message.type !== "emoji") {
      var text = message.data.text.replace("'", '"');
      text = text.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
      this.state.ws.send("sendMessage┐" + text)
    }
  }

  componentDidMount() {
    var textName = this.props.textName;
    var chapterId = this.props.chapterId;
    var this2 = this;
    document.getElementById('chat').addEventListener("paste", async function (e) {
      e.stopPropagation();
      var clipData = (e.clipboardData || e.originalEvent.clipboardData).items[0];

      if (clipData === undefined) {
        return
      }

      if (clipData.type === "text/html") {

        e.preventDefault()
        clipData.getAsString(data => {
          data = data.substring(data.indexOf('<img src="') + 10)
          data = data.substring(0, data.indexOf('"'))
          
          if (data.length > 0 && !data.startsWith('data:')) {
            this2.state.ws.send("uploadImage┐" + data+"┐"+"Bild aus Zwischenablage")
          }
        });
      } else {
        let file = (clipData.getAsFile());

        if (file && file.type.startsWith("image")) {
          let mydata = new FormData();
          mydata.append('File', file);
          mydata.append("textName", textName);
          mydata.append("chapterId", chapterId)
          mydata.append("uploadType", "Chat")
  
          axios.request({
            url: config.apiUrl + "/api/upload",
            method: 'POST',
            data: mydata
          }).then((response) => {
            if (response.status === 201) {
              this2.state.ws.send("uploadImage┐" + response.data.Url+"┐"+file.name)
            } else {
              response.json().then((response) => { toast.error(response.Message)})
            }
          }).catch((error) => {
            toast.error(error.message)
          });
        }
      }
    });
  }

  componentWillUnmount = () => {
    this.state.ws.close()
  }

  render() {
    if (this.state.chapterId !== this.props.chapterId) {
      this.state.ws.send("changeChapter┐" + this.props.textName + "┐" + this.props.chapterId);
      this.setState({ chapterId: this.props.chapterId })
    }

    return (<div id='chat'>
      <Launcher
        ws={this.state.ws}
        chapterId={this.state.chapterId}
        textName={this.props.textName}
        openImage={this.props.openImage}
        openPdf={this.props.openPdf}
        showEmoji={false}
        agentProfile={{
          teamName: "Notizbereich",
        }}
        onMessageWasSent={this.onMessageSent.bind(this)}
        messageList={this.state.messages}
        onChatOpened={this.props.onChatOpened}
        onChatClosed={this.props.onChatClosed}
      />
    </div>)
  }
}
export default ChatWindow
