import {connect} from 'react-redux'
import AddChapter from '../Components/addChapter'
import {addChapterToApi} from '../Fetching/chapters'

const mapDispatchToProps = dispatch => {
  return {
    onChapterAdd: (chapterName,textName) => {addChapterToApi(dispatch,chapterName,textName)}
  }
}

const mapStateToProps = (state) => {
  return {
    textName: state.currentText.textName
  }
}

const AddChapterConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddChapter)

export default AddChapterConnect
