export const GET_MEDIA = {
  type: "GET_MEDIA"
}

export const SET_MEDIA = (newMedia) => {
  return {type: "SET_MEDIA", media: newMedia}
}

export const TOGGLE_IMAGE_VIEWER = (newState) => {
  return {type: "TOGGLE_IMAGE_VIEWER" , isOpen: newState}
}

export const CHANGE_CURRENT_INDEX = (currentIndex) => {
  return {type: "CHANGE_CURRENT_INDEX", index: currentIndex}
}

export const SET_IMAGES = (newImage) => {
  return {type: "SET_IMAGES" , image: newImage}
}

export const RESET_IMAGES = {
  type: "RESET_IMAGES"
}

export const RESET_MEDIA = {
  type: "RESET_MEDIA"
}
