import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SendIcon from './icons/SendIcon';
import EmojiIcon from './icons/EmojiIcon';
import { toast } from 'react-toastify';
import config from '../../config';
const axios = require('axios');
const $ = require('jquery');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }


const allowedEndings = [ ".pdf", ".doc", ".docx", ".xls", ".xlsx"]

const uploadFileFromInput = (event, ws, chapterId, textName) => {
  event.preventDefault();
  let file = event.target.files[0];

  let fileEnding = file.name.substr(file.name.lastIndexOf('.'))

  if (file && (file.type.startsWith("image") || file.type.startsWith("application/pdf") || allowedEndings.includes(fileEnding))) {
    let mydata = new FormData();
    mydata.append('File', file);
    mydata.append("textName", textName);
    mydata.append("chapterId", chapterId)
    mydata.append("uploadType", "Chat")

    axios.request({
      url: config.apiUrl + "/api/upload",
      method: 'POST',
      data: mydata
    }).then((response) => {
      if (response.status === 201) {
        ws.send("uploadImage┐"+response.data.Url+"┐"+file.name)
      } else {
        response.json().then((response) => { toast.error(response.Message)})
      }
    }).catch((error) => {
      toast.error(error.message)
    });
  }

  event.target.files = null
}

var UserInput = function (_Component) {
  _inherits(UserInput, _Component);

  function UserInput() {
    _classCallCheck(this, UserInput);

    var _this = _possibleConstructorReturn(this, _Component.call(this));

    _this.state = {
      inputActive: false
    };
    return _this;
  }

  UserInput.prototype.handleKey = function handleKey(event) {
    //Entkommentieren um Enter wieder zum senden zu nutzen
    // if (event.keyCode === 13 && !event.shiftKey) {
    //   this._submitText(event);
    // }
  };

  UserInput.prototype._submitText = function _submitText(event) {
    event.preventDefault();
    let text = this.userInput.innerHTML.replace(/<br>/g, "").replace(/<p>/g, "\n\n").replace(/<\/p>/g, "")
    if (text && text.length > 0) {
      this.props.onSubmit({
        author: 'me',
        type: 'text',
        data: { text: text }
      });
      this.userInput.innerHTML = '';
    }
  };

  UserInput.prototype._handleEmojiPicked = function _handleEmojiPicked(emoji) {
    this.props.onSubmit({
      author: 'me',
      type: 'emoji',
      data: { emoji: emoji }
    });
  };

  UserInput.prototype.render = function render() {
    var _this2 = this;

    return React.createElement(
      'form',
      { className: 'sc-user-input ' + (this.state.inputActive ? 'active' : '') },
      <div>
        <button style={{ "height": "100%" }} className="sc-user-input--text" onClick={(e) => {e.preventDefault(); $('#chatImage').click() }}>+</button>
        <input onChange={(event) => uploadFileFromInput(event, this.props.ws, this.props.chapterId, this.props.textName)} style={{ "display": "none" }} id="chatImage" type="file" accept="image/*, .pdf, .doc, .docx, .xls, .xlsx" />
      </div>,
      React.createElement('div', {
        role: 'button',
        tabIndex: '0',
        onFocus: function onFocus() {
          _this2.setState({ inputActive: true });
        },
        onBlur: function onBlur() {
          _this2.setState({ inputActive: false });
        },
        ref: function ref(e) {
          _this2.userInput = e;
        },
        onKeyDown: this.handleKey.bind(this),
        contentEditable: 'true',
        placeholder: 'Füge eine Notiz hinzu...',
        className: 'sc-user-input--text'
      }),
      React.createElement(
        'div',
        { className: 'sc-user-input--buttons' },
        React.createElement(
          'div',
          { className: 'sc-user-input--button' },
          React.createElement(SendIcon, { onClick: this._submitText.bind(this) })
        )
      )
    );
  };

  return UserInput;
}(Component);

UserInput.propTypes = process.env.NODE_ENV !== "production" ? {
  onSubmit: PropTypes.func.isRequired,
  showEmoji: PropTypes.bool
} : {};

export default UserInput;