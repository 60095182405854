export const allUsers = (state = [false,[]], action) => {
    if(action.type === "SET_USERS") {
        return [false, action.users]
    }
    if (action.type === "GET_USERS") {
        return [true, state[1]]
    }
    if (action.type === "RESET") {
        return [false,[]]
    }

    return state
}