import React from "react";
import Select from "react-select";
import {toast} from 'react-toastify'
import config from "../config";
import AuthorSupportViewer from './AuthorSupportViewer'

import "../react-select.css";

class AuthorSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webSocket: undefined,
      sharingAuthors: [],
      selectedAuthor: undefined,
      subscribedAuthors: [],
      texts: {}
    }
    
    this.connectToWs();
  }

  componentWillUnmount = () => {
    if (this.state.webSocket) {
      this.state.webSocket.close();
    }
  }

  connectToWs = () => {
    let token = window.localStorage.getItem("lb4Token")
    const wsConnection = new WebSocket(config.webSocketUrl+'/api/authorSupport?token=' + token);
    var this2 = this;

    wsConnection.onmessage = (messageEvent) => {
      let message = messageEvent.data
      
      if (message.startsWith("closeConnection")) {
        let data = message.substring(16)
        let userId = parseInt(data)

        let subscribedAuthors = this.state.subscribedAuthors;
        subscribedAuthors = subscribedAuthors.filter(item => item.value.UserId != userId) 
        var texts = this.state.texts
        texts[userId] = ""
        this.setState({subscribedAuthors, texts})
      }

      if (message.startsWith("updateText")) {
        let data = message.substring(11).split("┐")
        let texts = this.state.texts

        texts[parseInt(data[0])] = data[1]
        this.setState({texts})
      }

      if (message.startsWith("getSharingAuthors")) {
        let authorsString = message.substring(18)
        let authors = []
        if (authorsString.length != 0) {
          authors = JSON.parse(authorsString)
          if (this.state.selectedAuthor
             && !authors.includes(this.state.selectedAuthor.data)) {
            this.setState({selectedAuthor: null})
          }
        }
        this.setState({sharingAuthors: authors})
      }
      
      if (message.startsWith("error")) {
        toast.error(message.slice(6))
      }
    };

    wsConnection.onopen = () => {
      this2.setState({webSocket: wsConnection})
      wsConnection.send("getSharingAuthors")
    }
  }

  getSelect = () => {
    return this.state.sharingAuthors.map((data, index) => {
      return {value: data, label: data.Username + " @ " + data.TextName + " | " + data.ChapterName};
    });
  }

  getEditors = () => {
    let rows = Math.ceil(this.state.subscribedAuthors.length / 3)
    let elems = []
    
    for (let index = 0; index < rows; index++) {
      let elemsInRow
      var remainingElems = this.state.subscribedAuthors.length - (index * 3)

      if (remainingElems >= 3) {
        elemsInRow = 3
      } else {
        elemsInRow = remainingElems
      }

      elems.push(this.getViewerComponents(0 + (index * 3), elemsInRow, 12 / elemsInRow))
    }

    return elems;
  }

  getViewerComponents = (startIndex, count, width) => {
    let authors = this.state.subscribedAuthors.slice(startIndex, startIndex + count)
    return (
      <div className="row">
        {
          authors.map((data) => {
            return this.getViewerComponent(width, data.label, data.value.UserId)
          })
        }
      </div>
    )
  }

  getViewerComponent = (width, label, userId) => {
    return (<AuthorSupportViewer 
      columns={width}
      text={this.state.texts[userId]}
      userTag={label}
      webSocket={this.state.webSocket}
    />)
  }

  onAddClick = () => {
    let subscribedAuthors = this.state.subscribedAuthors;

    if (!subscribedAuthors.some((item) => item.value.UserId == this.state.selectedAuthor.value.UserId)) {
      subscribedAuthors.push(this.state.selectedAuthor) 
      this.state.webSocket.send("subscribeToAuthor┐" + this.state.selectedAuthor.value.UserId)
      var texts = this.state.texts
      texts[this.state.selectedAuthor.value.UserId] = ""
      this.setState({subscribedAuthors, texts})
    }
  }

  onRemoveClick = () => {
    let subscribedAuthors = this.state.subscribedAuthors;
    
    if (subscribedAuthors.some((item) => item.value.UserId == this.state.selectedAuthor.value.UserId)) {
      this.state.webSocket.send("unsubscribeFromAuthor┐" + this.state.selectedAuthor.value.UserId)
      subscribedAuthors = subscribedAuthors.filter(item => item.value.UserId != this.state.selectedAuthor.value.UserId) 
      var texts = this.state.texts
      texts[this.state.selectedAuthor.value.UserId] = ""
      this.setState({subscribedAuthors, texts})      
    }
  }

  render() {
    return (
      <div className="container" style={{height: "95vh", overflowY: "auto"}}>
        <h1 style={{ "text-align": "center" }}>Autoren Support</h1>
        <div>
        <Select
          value={this.state.selectedAuthor}
          name="authorselect"
          options={this.getSelect()}
          placeholder="Autor auswählen"
          noResultsText="Kein Autor gefunden"
          onChange={value => {
            if (!(value === null))
              this.setState({ selectedAuthor: value });
          }}
        />
        <button style={{marginTop: "10px"}} className="btn" onClick={() => this.onAddClick()}>Hinzufügen</button>
        <button style={{marginTop: "10px"}} className="btn right" onClick={() => this.onRemoveClick()}>Entfernen</button>
        </div>
        <br />
        {this.getEditors()}
      </div>
    );
  }
}

export default AuthorSupport;
