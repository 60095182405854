import React from 'react';

var ReconnectMessage = function ReconnectMessage(props) {
  return (
    <div 
      className="sc-message--text" 
    >{props.data.text}<button className="btn" onClick={() => {props.chatWindow.connectToWs()}}>Wiederverbinden</button></div>
  )
};

export default ReconnectMessage; 