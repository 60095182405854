import { logIn } from '../actions/loginActions'
import { toast } from 'react-toastify';
import { authenticateAndSendToAPI, clientgraph } from './connection'
import { sha512 } from 'js-sha512'

var logintoast = null;

export const checkLogin = (username, password, login, dispatch, token) => {
  let query = `
  {
  login(
`
  if (token === undefined) {
    query += `userName: "` + username + `" ,password: "` + sha512(password).toUpperCase() + `"`
  } else {
    query += `token: "` + token + `"`
    login.hideLoginButton();
  }
  query += `)
  {
    isCorrect
    role
    userId
    token
  }
}`
  if (logintoast != null) {
    toast.dismiss(logintoast);
    logintoast = null;
  }
  logintoast = toast.info("Anmeldung wird ausgeführt...", {
    closeButton: false,
    autoClose: false,
    closeOnClick: false,
    draggable: false,
  });

  clientgraph.query(query).then(result => {
    if (!result.login.isCorrect) {
      if (token === undefined)
        login.setState({ correct: false });
      setTimeout(() => {
        toast.update(logintoast, {
          render: "Anmeldung fehlgeschlagen",
          type: toast.TYPE.ERROR,
          autoClose: 1500
        });
      }, 0);
      window.localStorage.removeItem('lb4Token');
      login.showLoginButton()
    } else {
      login.setState({ correct: true });
      window.localStorage.setItem('lb4Token', result.login.token)
      setTimeout(() => {
        toast.update(logintoast, {
          render: "Erfolgreich angemeldet!",
          type: toast.TYPE.SUCCESS,
          autoClose: 1500
        });
      }, 0);
    }
    dispatch(logIn(result.login))
  }, error => {
    var text = "API nicht erreichbar"
    if (error) {
      if (error.message.includes("GraphQL Error")) {
        text = error.message.replace("GraphQL Error: ","")
      }
    }
    login.setState({ correct: true });
    setTimeout(() => {
      toast.update(logintoast, {
        render: text,
        type: toast.TYPE.ERROR,
        autoClose: 1500
      });
    }, 0);
    login.showLoginButton()
  });
}

export const tryRegister = (username, password, className) => {
  let query = `
        mutation
        {
        register(newUserName: "`+ username + `" ,newPassword: "` + sha512(password).toUpperCase() + `" className: "` + className + `")
        }
  `
  authenticateAndSendToAPI(query,result => {
    if (result.register) {
      toast.success("Benutzer erfolgreich erstellt!")
    }
    else {
      toast.error("Benutzer existiert bereits!")
    }
  }, error => { toast.error("An error occured: " + error) });
}
