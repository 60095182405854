import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
class HistoryView extends Component {

    renderTable() {
        var columns = [
            {
                Header: 'Kapitel',
                accessor: 'chapterName'
            },
            {
                Header: 'Start',
                accessor: 'timestamp'
            },
            {
                Header: 'Ende',
                accessor: 'endTimestamp'
            },
            {
                Header: 'Dauer',
                accessor: 'duration'
            },
            {
                Header: 'Aktion',
                accessor: 'historyActionName'
            },
            {
                Header: 'ObjektName',
                accessor: 'objectName'
            }
        ]
        return (
            <ReactTable className="-striped -centered"
                data={this.props.history}
                columns={columns}
                previousText='Vorherige Seite'
                nextText='Nächste Seite'
                loadingText='Wird geladen...'
                noDataText='Der Verlauf ist leer, schaue dir deinen ersten Text an indem du auf Übersicht klickst.'
                pageText='Seite'
                ofText='von'
                rowsText='zeilen'
                pageSize={15}

                // Accessibility Labels
                pageJumpText='spring zu Seite'
                rowsSelectorText='Zeilen pro Seite'
            />
        )
    }

    exportAsCSV = () => {
        let startLine = ["Kapitel,Start,Ende,Dauer,Aktion,ObjektName"]

        let lines = this.props.history.reverse().map(this.getLineFromHistory)
        const csvData = [startLine, ...lines].flat().join('\n')
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Export.csv";
        a.click();

        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 500);
    }

    fields = ["chapterName", "timestamp", "endTimestamp", "duration", "historyActionName", "objectName"]

    getLineFromHistory = (historyEntry) => {
        return this.fields.map(field => this.sanatizeString(historyEntry[field])).join(',')
    }

    sanatizeString = (string) => {
        if (!string) {
            string = ""
        }
        return '"' + string.replace(/"/g, "\"") + '"'
    }

    componentDidMount() {
        this.props.onLoad();
    }

    render() {
        return (
            <div className="historyContainer">
                <h1 style={{ "textAlign": "center" }}>Verlaufsübersicht</h1>
                <br />
                {this.renderTable()}
                <br/>
                <button className='btn right' onClick={() => this.exportAsCSV()}>Verlauf Exportieren</button>
            </div>
        )
    }
}

export default HistoryView