export const CHANGE_TEXT = (newChapterId) => {
  return {type: "CHANGE_TEXT", chapterId: newChapterId}
}

export const SET_CHAPTERS = (chapterData) => {
  return {type: "SET_CHAPTERS", chapters: chapterData}
}

export const SET_CHAPTER_INDEX = (newIndex) => {
  return {type: "SET_CHAPTER_INDEX", index: newIndex}
}

export const RESET_CHAPTER_INDEX = {
  type: "RESET_CHAPTER_INDEX"
}

export const RESET_CHAPTER_TEXT = {
  type: "RESET_CHAPTER_TEXT"
}

export const GET_CHAPTERS = {
  type: "GET_CHAPTERS"
}

export const LOAD_CHAPTER = {
  type: "LOAD_CHAPTER"
}

export const SET_CHAPTER_TEXT = (newChapterText, newChapterId, newChapterName, newVersion, newLogoUrl, markers, isVisible) => {
  return {
    type: "SET_CHAPTER_TEXT",
    chapterText: newChapterText,
    markers: markers,
    chapterId: newChapterId,
    chapterName: newChapterName,
    version: newVersion,
    logoUrl: newLogoUrl,
    isVisible: isVisible
  }
}


export const SET_QUIZ_URL = (newQuizUrl) => {
  return {type: "SET_QUIZ", quizUrl: newQuizUrl}
}
