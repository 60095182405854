export const LEFT_SIDE = {
  type: "LEFT_SIDE"
}

export const RIGHT_SIDE = {
  type: "RIGHT_SIDE"
}

export const TOGGLE_VIEW = (newModeString) => {
  return {type: "TOGGLE_VIEW", newMode: newModeString}
}

export const RESET_MODE = {
  type: "RESET_MODE"
}
