import { SET_MEDIA } from '../actions/mediaActions'
import { toast } from 'react-toastify';
import {authenticateAndSendToAPI} from './connection'
import { sanatizeString } from '../common';

export const editMediaOrder = (dispatch,media,chapterId,textName) => {
  const mediaIds = media.map((value) => {return value.mediaId})
  
  const query = `
  mutation
  {
    editMediaOrder(mediaIds: "`+ mediaIds.join(",") +`" chapterId: `+chapterId+` textName: "`+textName+`")
  }
  `
  authenticateAndSendToAPI(query,(result) => {
    if (result.editMediaOrder) {
      toast.success("Reihenfolge wurde erfolgreich geändert!")
      getMediaFromApi(dispatch, chapterId, textName)
    }
    else {
      toast.error("Reihenfolge konnte nicht geändert werden.")
    }
  })
}

export const editMediaNameOnAPI = (chapterId, displayText, mediaId, textName,dispatch) => {
  const query = `
  mutation
  {
    editMediaName(chapterId: `+ chapterId + ` newMediaName: "` + sanatizeString(displayText) +`" mediaId: `+mediaId+` textName: "`+textName+`")
  }
  `

  authenticateAndSendToAPI(query,(result) => {
    if (result.editMediaName) {
      toast.success("Medium wurde erfolgreich geändert!")
      getMediaFromApi(dispatch, chapterId, textName)
    }
    else {
      toast.error("Medium konnte nicht geändert werden.")
    }
  })
}

export const addMediaToChapter = (dispatch, chapterId, displayText, url, childData, textName) => {
  const query = `
  mutation
  {
    addMedia(chapterId: `+ chapterId + ` displayText: "` + sanatizeString(displayText) + `" url: "` + url + `" childData: "` + childData + `" textName: "` + textName + `")
  }
  `

  authenticateAndSendToAPI(query,(result) => {
    if (result.addMedia) {
      toast.success("Medium wurde erfolgreich hinzugefügt!")
      getMediaFromApi(dispatch, chapterId, textName)
    }
    else {
      toast.error("Medium konnte nicht hinzugefügt werden.")
    }
  })
}

export const deleteMediaFromChapter = (dispatch, mediaId, chapterId, textName) => {
  const query = `
  mutation
  {
    deleteMedia(mediaId: `+ mediaId + ` textName: "` + textName + `" chapterId: ` + chapterId + `)
  }
  `

  authenticateAndSendToAPI(query,(result) => {
    if (result.deleteMedia) {
      toast.success("Medium wurde erfolgreich gelöscht!")
      getMediaFromApi(dispatch, chapterId, textName)
    }
    else {
      toast.error("Medium konnte nicht gelöscht werden.")
    }
  })
}

export const getMediaFromApi = (dispatch, chapterId, textName) => {
  const query = `
  {
    medias(chapterId: `+ chapterId + ` textName: "` + textName + `") {
    mediaId
    url
    childData
    displayText
    orderIndex
    }
  }
  `
  authenticateAndSendToAPI(query,(data) => {
    dispatch(SET_MEDIA(data.medias))
  })
}
