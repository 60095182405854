import {authenticateAndSendToAPI} from "./connection";

export const deleteTokenFromApi = () => {
  const query = `
    mutation
    {
      deleteToken()
    }
    `
    authenticateAndSendToAPI(query)
}
