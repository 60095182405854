import React from 'react';
import anchorme from "anchorme";
import { Document, Page, pdfjs } from "react-pdf";



var TextMessage = function TextMessage(props) {
  let url = "";
  let type = ""
  if (props.data.text.includes("<img src=") || props.data.text.includes("<Pdf>")) {
    if (props.data.text.includes("<img src=")) {
      url = props.data.text.substring(props.data.text.indexOf('<img src="') + 10)
      type = "image"
    } else {
      url = props.data.text.substring(props.data.text.indexOf('<Pdf>') + 6)
      type = "pdf"
    }

    url = url.substring(0, url.indexOf('"'))
    url = url.replace(/&amp;/g, '&')
  }
  return (
    <div className="row smallRow">
      <div className="col s1">
        {getDeleteButton(props)}
      </div>
      <div className="col s11">
        {
          getInnerHtml(type, url, props)
        }
      </div>
    </div>
  )
};

var getInnerHtml = (type, url, props) => {
  if (type === "pdf") {
    return (
      <div className="sc-message--text">
        <a href={url} target="_blank">
          In neuem Tab öffnen
        </a>
        <div onClick={() => {
          if (url.length > 0) {
            props.openPdf(url)
          }
        }}>
        <Document
          className={"mediaPdfResponsive"}
          file={url}
        >
          <Page renderTextLayer={false} pageNumber={1} />
        </Document>
        </div>
      </div>
    )
  } else {
    return (
      <div className="sc-message--text"
        onClick={() => {
          if (url.length > 0) {
            props.openImage(url)
          }
        }}
        dangerouslySetInnerHTML={{ __html: anchorme(props.data.text, { attributes: [{ name: "target", value: "_blank" }] }) }}
      ></div>
    )
  }
}

var getDeleteButton = (props) => {
  if (props.author === "me") {
    return <span onClick={() => { props.ws.send("deleteMessage┐" + props.id) }} aria-hidden="true" className="chatDelete">&times;</span>
  }

}

export default TextMessage;