import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import jQuery from "jquery";
import { getRandomNoCacheString } from '../common'
import color_map from '../config/colorPickerMap'
import { endHistoryEntryOnApi } from "../Fetching/history";
import { CHAPTER_OPENED, INTERNAL_MEDIUM_OPENED, MEDIUM_EXPANDED } from "../constants/history";

class TextViewer extends React.Component {
  constructor(props) {
    super(props);

    let text = this.props.text[5]

    this.state = {
      text: text,
      chapterId: this.props.text[0],
      chapterName: this.props.text[2],
      logoUrl: this.props.logoUrl,
      isMarkMode: false,
      editor: undefined,
      version: this.props.text[3]
    };
  }

  onMarkedChanged = (changes) => {
    const chapterId = this.state.chapterId;
    let chapterText = this.state.editor.getContent();

    chapterText = chapterText.replace(/\\/g, "&#92;")
    .replace(/\r?\n|\r/g,"")
    .replace(/"/g,'\\\"');

    if (chapterText.startsWith("<p><br></p>")) {
      chapterText = chapterText.replace("<p><br></p>", "");
    }

    if (!changes) {
      changes = this.getChanges(chapterText)
    } else {
      this.setState({text: this.props.text[1]})
    }
    
    this.props.onMarkedChanged(chapterId, changes);
  };

  getChanges = (chapterText) => {
      let changes = []
      chapterText = chapterText.replace(/\\\"/g, "\"")

      let startIndex = -1;
      while((startIndex = chapterText.indexOf("<span class=\"usermarker", startIndex + 1)) !== -1)
      {
        let endOfStartTag = chapterText.indexOf(">", startIndex) + 1
        let startTag = chapterText.substring(startIndex, endOfStartTag)

        let endTag = ""
        if (startTag.indexOf(" ") == -1) {
          endTag = startTag.replace("<", "</")
        } 
        else
        {
          endTag = "</" + startTag.substring(1, startTag.indexOf(" ")) + ">"
        } 

        let startOfEndTag = chapterText.indexOf(endTag, endOfStartTag)
        let length = startOfEndTag - endOfStartTag

        changes.push({
          startIndex,
          startTag,
          endTag,
          length
        })
      }

      return changes;
  }

  componentDidMount() {

    jQuery(document).on("dragstart", event => {
      event.preventDefault();
    });

    var this2 = this;
    jQuery('#viewer').on('click', 'a', function (e) {
      if (!this2.state.isMarkMode) {
        let href = jQuery(this).attr('href')
        if (href !== '' && href !== '#' && href !== '#!') {
          let clone = jQuery(this).clone(true)
          clone[0].click();
        }
      }
    });
  }

  componentDidUpdate() {
    this.activateTooltips();
  }

  activateTooltips = () => {
    var elems = document.querySelectorAll(".tooltipped");
    for (let index = 0; index < elems.length; index++) {
      const element = elems[index];
      element.ondblclick = () => {
        if (this.state.isMarkMode) {
          this.onNoteDoubleCLick(element);
        }
      };
    }
    window.M.Tooltip.init(elems, {});
  };

  componentWillUnmount = (props) => {
    this.props.endHistoriesForChapter()

    jQuery(document).off("dragstart");

    jQuery('#viewer').off('click', 'a')
  }

  handleEditorChange = (content, editor) => {
    editor.shortcuts.shortcuts = [];
  };

  getChapterNameHTML = chapterName => {
    return (
      <div>
        <p>
          <b style={{ fontWeight: "bold" }}>{chapterName}</b>
        </p>
      </div>
    );
  };

  getLogoHTML = logoUrl => {
    if (logoUrl !== "" && logoUrl !== undefined) {
      return (
        <div>
          <img
            src={logoUrl + getRandomNoCacheString()}
            alt="undefined"
            style={{
              marginRight: "25px",
              float: "right",
              height: "80px",
              width: "auto"
            }}
          />
        </div>
      );
    }
    return "";
  };

  onNoteDoubleCLick = element => {
    let editor = this.state.editor;
    let iniText = element.getAttribute("data-tooltip");
    let this2 = this

    editor.windowManager.open({
      title: "Notiz bearbeiten / löschen",
      height: 1000,
      body: {
        type: "panel",
        items: [
          {
            type: "textarea",
            name: "noteText",
            label: "Notiz:"
          }
        ]
      },
      buttons: [
        {
          type: "cancel",
          name: "closeButton",
          text: "Abbrechen"
        },
        {
          type: "custom",
          name: "deleteButton",
          text: "Löschen"
        },
        {
          type: "submit",
          name: "editButton",
          text: "Bearbeiten",
          primary: true
        }
      ],
      initialData: {
        noteText: iniText
      },
      onSubmit: function (api) {
        var data = api.getData();
        var text = data.noteText;
        if (text !== '') {
          element.setAttribute("data-tooltip", text);
        }
        else {
          editor.formatter.remove("noteFormat", { text: iniText }, element);
        }
        this2.onMarkedChanged()
        api.close();
      },
      onAction: function (api) {
        api.close();
        editor.formatter.remove("noteFormat", { text: iniText }, element);
        this2.onMarkedChanged()
      }
    });
  };

  addNoteButtonAction = () => {
    let editor = this.state.editor;
    let this2 = this;

    editor.windowManager.open({
      title: "Notiz hinzufügen",
      height: 1000,
      body: {
        type: "panel",
        items: [
          {
            type: "alertbanner",
            level: "info",
            text:
              "zum bearbeiten oder löschen bitte den markierten Text doppelt anklicken",
            icon: "help"
          },
          {
            type: "textarea",
            name: "noteText",
            label: "Notiz:"
          }
        ]
      },
      buttons: [
        {
          type: "cancel",
          name: "closeButton",
          text: "Abbrechen"
        },
        {
          type: "submit",
          name: "submitButton",
          text: "Hinzufügen",
          primary: true
        }
      ],
      initialData: {
        noteText: ""
      },
      onSubmit: function (api) {
        var data = api.getData();
        var text = data.noteText;
        if (text !== '') {
          editor.formatter.apply("noteFormat", { text: text });
          this2.activateTooltips();
          this2.onMarkedChanged()
        }
        api.close();
      }
    });
  };

  render() {
    let this2 = this;
    let textForViewer = this.props.text[5];
    const { chapterId, version, logoUrl } = this.state;
    if (
      chapterId !== this.props.text[0] ||
      logoUrl !== this.props.logoUrl ||
      version !== this.props.version
    ) {
      this.setState({
        chapterId: this.props.text[0],
        text: textForViewer,
        chapterName: this.props.text[2],
        logoUrl: this.props.logoUrl,
        version: this.props.version
      });
    }

    return (
      <div id="viewer" className="viewer">
        <ReadModeButton
          isMarkMode={this.state.isMarkMode}
          changeEditorMode={() => {
            this.setState({ isMarkMode: !this.state.isMarkMode });
          }}
        />
        <PrintButton
          isMarkMode={this.state.isMarkMode}
          onPrintClicked={() => this.props.onPrintClicked(this.state.editor)}
        />
        <DeleteMarksButton
          isMarkMode={this.state.isMarkMode}
          onDeleteMarksClicked={() => this.onMarkedChanged([])}
        />
        <br />
        <br />
        {this.getLogoHTML(this.state.logoUrl)}
        <br />
        {this.getChapterNameHTML(this.state.chapterName)}
        <br />
        <Editor
          value={this.state.text}
          disabled={!this.state.isMarkMode}
          onEditorChange={this.handleEditorChange}
          inline={true}
          apiKey="zrpdoc8n1fqo04bn3f8cj1rk0c6roro96pew3hvdkm64v8qi"
          init={{
            setup: editor => {
              editor.ui.registry.addButton("addNote", {
                text: "Notiz hinzufügen",
                onAction: this2.addNoteButtonAction
              });
            },
            formats: {
              hilitecolor : {inline : 'span', classes : 'usermarker', styles : {backgroundColor : '%value'}},
            },
            init_instance_callback: function (editor) {
              this2.setState({ editor });
              editor.formatter.register("noteFormat", {
                inline: "span",
                styles: { 'background-color': 'rgb(206, 212, 217)' },
                classes: "usermarker tooltipped",
                attributes: {
                  "data-position": "top",
                  "data-tooltip": "%text"
                }
              });
              editor.on("Cut", function (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
              });
              editor.on("KeyDown", function (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
              });
              editor.on("KeyPress", function (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
              });
              editor.on("KeyUp", function (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
              });
              editor.on("Paste", function (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
              });
              editor.on("Undo", function (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
              });
              editor.on("Redo", function (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
              });

              editor.on("ExecCommand", function (e) {
                if (e.command == "mceApplyTextcolor") {
                  this2.onMarkedChanged()
                }
              })

              editor.on("Change", function (e) {
                if (e.originalEvent && e.originalEvent.type !== "blur") {
                  e.target.setContent(this2.state.text);
                }
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
              });
            },
            readonly: 1,
            height: 500,
            plugins: ["print"],
            toolbar: ["backcolor | addNote"],
            menubar: false,
            color_map,
            visual: false,
          }}
        />
        <br />
      </div>
    );
  }
}

class PrintButton extends React.Component {
  render() {
    return (
      <button
        className="btn left doNotPrint editorbtn"
        style={{ marginLeft: this.props.isMarkMode ? "9.2em" : "14.1em" }}
        onClick={() => this.props.onPrintClicked()}
      >
        Kapitel drucken
      </button>
    );
  }
}

class DeleteMarksButton extends React.Component {
  render() {
    return (
      <button
        className="btn left doNotPrint editorbtn"
        style={{ marginLeft: this.props.isMarkMode ? "20.9em" : "25.75em" }}
        onClick={() => this.props.onDeleteMarksClicked()}
      >
        Zurücksetzen
      </button>
    );
  }
}

class ReadModeButton extends React.Component {
  textMode() {
    return this.props.isMarkMode ? "Lesemodus" : "Markierungsmodus";
  }

  render() {
    return (
      <button
        className={
          "btn left doNotPrint editorbtn" +
          (this.props.isMarkMode ? " inmark" : " inread")
        }
        onClick={() => this.props.changeEditorMode()}
      >
        {this.textMode()}
      </button>
    );
  }
}

export default TextViewer;
