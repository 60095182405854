import { getTextsByUser } from './getTextsByUser'
import { SET_TEXT_CLASSES, SET_SCHEMA } from '../actions/textActions'
import { toast } from 'react-toastify';
import {authenticateAndSendToAPI} from './connection'

export const addTextToDatabase = (dispatch, textName) => {
  const query = `
  mutation
  {
    addText(textName: "`+ textName + `")
  }
  `

  authenticateAndSendToAPI(query,(result) => {
    if (result.addText) {
      getTextsByUser(dispatch)
      toast.success("Text wurde erfolgreich hinzugefügt!")
    }
    else {
      toast.error("Text konnte nicht hinzugefügt werden.")
    }
  }, error => { toast.error(error.message)})
}

export const deleteTextFromDatabase = (dispatch, textName) => {
  const query = `
  mutation
  {
    deleteText(textName: "`+ textName + `")
  }
  `

  authenticateAndSendToAPI(query,(result) => {
    if (result.deleteText) {
      getTextsByUser(dispatch)
      toast.success("Text wurde erfolgreich gelöscht!")
    }
    else {
      toast.error("Text konnte nicht gelöscht werden.")
    }
  })
}

export const getAllClassesForText = (dispatch, textName) => {
  const query = `
  {
    textClasses(textName: "`+ textName + `")
  }
  `

  authenticateAndSendToAPI(query,(result) => {
    dispatch(SET_TEXT_CLASSES(result.textClasses))
  })
}

export const renameText = (dispatch, oldTextName, newTextName, newSubtitle) => {
  const query = `
  mutation
  {
    editText(textName: "`+ oldTextName + `" newTextName: "` + newTextName + `" subtitle: "` + newSubtitle + `")
  }
  `
  
  authenticateAndSendToAPI(query,(result) => {
    if (result.editText) {
      dispatch(SET_SCHEMA(newTextName))
      getTextsByUser(dispatch)
      toast.success("Textname/Untertitel erfolgreich geändert!")
    } else {
      toast.error("Textname/Untertitel konnte nicht geändert werden.")
    }
  })
}

export const updateClassesForText = (dispatch, textName, newClasses) => {
  const query = `
  mutation
  {
    editClassesForText(textName: "`+ textName + `" newClasses: [` + newClasses.map((data) => { return "\"" + data + "\"" }) + `])
  }
  `
  
  authenticateAndSendToAPI(query,(result) => {
    if (result.editClassesForText) {
      getAllClassesForText(dispatch, textName)
      toast.success("Klassen erfolgreich bearbeitet!")
    }
    else {
      toast.error("Klassen konnten nicht bearbeitet werden.")
    }
  })
}
