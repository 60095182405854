import React, { Component } from "react";
import Select from "react-select";

import "../react-select.css";

let clicked;

class ManageTexts extends Component {
  constructor(props) {
    super(props);
    this.state = { newTextClass: "", selected: "" };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    let error = false;
    if (clicked === "add") {
      if (event.target.displayText.value === "") {
        error = true;
        this.setState({ newTextClass: "has-error" });
      }

      if (!error) {
        this.props.addNewText(event.target.displayText.value);
      }
    } else {
      if (this.state.selected !== "") {
        this.props.deleteText(this.state.selected.value);
        this.setState({ selected: "" });
      }
    }
  }

  getSelect() {
    var allTexts = [];
    for (let index = 0; index < this.props.texts.length; index++) {
      const text = this.props.texts[index];
      if (!text.isReadOnly)
        allTexts.push({ value: text.textName, label: text.textName });
    }
    return allTexts;
  }

  getDisplay(style) {
    return (
      <div>
        <h1 style={{ textAlign: "center" }}>Textverwaltung</h1>
        <br />
        <form method="post" onSubmit={this.handleSubmit}>
          <div className="container form-group ">
            <div className={this.state.newTextClass}>
              <label className="control-label">
                <b>Neuen Text erstellen: </b>
              </label>
              <br />
              <input
                type="text"
                className="fullInput form-control"
                placeholder="Name des Textes"
                name="displayText"
              />
              {this.state.newTextClass === "has-error" ? (
                <div>
                  <span className="help-block">
                    Bitte dieses Feld ausfüllen
                  </span>
                  <br />
                </div>
              ) : (
                ""
              )}
            </div>

            <button
              className="btn "
              onClick={() => {
                clicked = "add";
              }}
              type="submit"
            >
              Text hinzufügen
            </button>
            <br />
            <br />
            <br />

            <label className="control-label">
              <b>Text löschen:</b>
            </label>
            <Select
              name="form-field-name"
              value={this.state.selected}
              options={this.getSelect()}
              noResultsText="Kein Text wurde gefunden"
              placeholder="Text zum löschen auswählen"
              onChange={value => {
                if (!(value === null)) this.setState({ selected: value });
              }}
            />
            <br />
            <button
              className="btn "
              onClick={() => {
                clicked = "delete";
              }}
              type="submit"
            >
              Text löschen
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    const style = {
      display: "-webkit-flex",
      WebkitFlex: "0 1 auto",
      flex: "0 1 auto",
      WebkitFlexDirection: "column",
      flexDirection: "column",
      WebkitFlexGrow: 1,
      flexGrow: 1,
      WebkitFlexShrink: 0,
      flexShrink: 0,
      WebkitFlexBasis: "25%",
      flexBasis: "25%",
      maxWidth: "25%",
      height: "200px",
      WebkitAlignItems: "center",
      alignItems: "center",
      WebkitJustifyContent: "center",
      justifyContent: "center"
    };
    return this.getDisplay(style);
  }
}

export default ManageTexts;
