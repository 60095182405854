import React, { Component } from "react";
import Select from "react-select";

let clicked;
let initState = {
  selectedTokenForSelectComponent: "",
  selectedToken: undefined,
  tokenClass: "",
  remainingUsersClass: "",
  newClassClass: "",
  roleDropdown: 0,
  selectedClass: "",
  validUntilClass: ""
};

let roles = [
  { value: 0, label: "Leser" },
  { value: 1, label: "Autor" },
  { value: -1, label: "TestUser (Für automatische Tests)" }
]


class ManageRegisterTokens extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.props.onLoad();
  }

  handleSubmit(event) {
    event.preventDefault();
    let error = false;

    if (clicked === "delete") {
      if (this.state.selectedTokenForSelectComponent === "") {
        error = true;
        this.setState({ tokenClass: "has-error" });
        return;
      }

      this.props.deleteRegisterToken(this.state.selectedTokenForSelectComponent.value);
      document.getElementById("registerToken").value = "";
      return;
    }

    if (clicked === "update" || clicked === "add") {
      if (event.target.validUntil.value === "") {
        error = true;
        this.setState({ validUntilClass: "has-error" });
      }

      if (event.target.remainingUsers.value === "") {
        error = true;
        this.setState({ remainingUsersClass: "has-error" });
      } else {
        let remainUsers = Number(event.target.remainingUsers.value);
        if (isNaN(remainUsers) || remainUsers < 0 || remainUsers > 100) {
          error = true;
          this.setState({ remainingUsersClass: "has-error" });
        }
      }

      if (this.state.selectedClass === "") {
        error = true;
        this.setState({ newClassClass: "has-error" });
      }

      if (!error) {
        if (clicked === "update") {
          if (this.state.selectedTokenForSelectComponent === "") {
            error = true;
            this.setState({ tokenClass: "has-error" });
            return;
          }
          if (event.target.registerToken.value === "") {
            error = true;
            this.setState({ tokenClass: "has-error" });
            return;
          }
          this.props.updateRegisterToken(
            this.state.selectedTokenForSelectComponent.value,
            event.target.registerToken.value,
            event.target.validUntil.value,
            event.target.remainingUsers.value,
            this.state.selectedClass.value,
            this.state.roleDropdown
          );
        } else {
          this.props.addRegisterToken(
            event.target.registerToken.value,
            event.target.validUntil.value,
            event.target.remainingUsers.value,
            this.state.selectedClass.value,
            this.state.roleDropdown
          );
          this.setState(initState);
          document.getElementById("registerToken").value = "";
        }
      }
    }
  }

  getMainSelect() {
    return this.props.registerTokens.map(registerToken => {
      return { value: registerToken.token, label: registerToken.token };
    });
  }

  getClassSelect() {
    return this.props.classes[1].map(data => {
      return { value: data, label: data };
    });
  }

  updateFields(value) {
    let token = this.props.registerTokens.filter(token => {
      return token.token === value.value;
    })[0];

    this.setState({
      selectedToken: token,
      selectedTokenForSelectComponent: value,
      selectedClass: { label: token.className, value: token.className },
      roleDropdown: token.roleId
    });
    document.getElementById("registerToken").value = value.label;
    document.getElementById("remainingUsers").value = token.remainingUsers;
    document.getElementById("validUntil").value = token.validUntil;
  }

  getDisplay(style) {
    return (
      <div>
        <h1 style={{ textAlign: "center" }}>RegisterToken Verwaltung</h1>
        <br />
        <form method="post" onSubmit={this.handleSubmit}>
          <div className="container form-group ">
            <div
              style={{ borderBottomStyle: "solid" }}
              className={this.state.userClass}
            >
              <label className="control-label">
                <b>Token auswählen: </b>
              </label>
              <br />
              <Select
                name="form-field-name"
                value={this.state.selectedTokenForSelectComponent}
                clearable={true}
                options={this.getMainSelect()}
                noResultsText="Kein Register Token wurde gefunden"
                placeholder="Register Token zum bearbeiten auswählen"
                onChange={value => {
                  if (!(value === null)) this.updateFields(value);
                }}
              />
              <br />
            </div>
            <br />
            <br />
            <div className={this.state.tokenClass}>
              <label className="control-label">
                <b>Token: </b>
              </label>
              <br />
              <input
                id="registerToken"
                type="text"
                className="fullInput form-control"
                placeholder="Der Token"
                name="registerToken"
              />
              {this.state.tokenClass === "has-error" ? (
                <div>
                  <span className="help-block">
                    Bitte korrigieren Sie den Inhalt dieses Feldes oder wählen
                    Sie im Dropdown einen Token aus.
                  </span>
                  <br />
                </div>
              ) : (
                  ""
                )}
            </div>
            <div className={this.state.remainingUsersClass}>
              <label className="control-label">
                <b>Anzahl der Nutzer die diesen Code benutzen können: </b>
              </label>
              <br />
              <input
                id="remainingUsers"
                type="text"
                className="fullInput form-control"
                placeholder="Anzahl der Nutzer die diesen Code benutzen können"
                name="remainingUsers"
              />
              {this.state.remainingUsersClass === "has-error" ? (
                <div>
                  <span className="help-block">
                    Dies muss eine Zahl und zwischen 0 und 100 sein.
                  </span>
                  <br />
                </div>
              ) : (
                  ""
                )}
            </div>
            <div className={this.state.validUntilClass}>
              <label className="control-label">
                <b>Gültig bis: </b>
              </label>
              <br />
              <input
                id="validUntil"
                type="text"
                className="fullInput form-control"
                placeholder="dd.MM.yyyy hh:mm bsp: 24.12.2019 12:30"
                name="validUntil"
              />
              {this.state.validUntilClass === "has-error" ? (
                <div>
                  <span className="help-block">
                    Bitte korrigieren Sie den Inhalt dieses Feldes.
                  </span>
                  <br />
                </div>
              ) : (
                  ""
                )}
            </div>
            <div className={this.state.newClassClass}>
              <label className="control-label">
                <b>Klasse: </b>
              </label>
              <br />
              <Select
                name="form-field-name"
                value={this.state.selectedClass}
                clearable={true}
                options={this.getClassSelect()}
                noResultsText="Keine Klasse wurde gefunden"
                placeholder="Klasse die die Nutzers bekommen"
                onChange={value => {
                  if (!(value === null))
                    this.setState({ selectedClass: value });
                }}
              />
              {this.state.newClassClass === "has-error" ? (
                <div>
                  <span className="help-block">
                    Bitte korrigieren Sie den Inhalt dieses Feldes.
                  </span>
                  <br />
                </div>
              ) : (
                  ""
                )}
            </div>
            <br />
            <div>
              <label className="control-label">
                <b>Rolle: </b>
                <Select
                  name="form-field-name"
                  value={roles.filter(role => role.value == this.state.roleDropdown)}
                  clearable={false}
                  options={roles}
                  noResultsText="Fehler!"
                  placeholder="Role des ausgewählten Nutzers"
                  onChange={value => {
                    if (!(value === null))
                      this.setState({ roleDropdown: value.value });
                  }}
                />
              </label>
            </div>
            <br />
            <button
              className="btn "
              onClick={() => {
                clicked = "add";
              }}
              type="submit"
            >
              Token generieren
            </button>
            <button
              style={{ marginLeft: "2px" }}
              className="btn "
              onClick={() => {
                clicked = "update";
              }}
              type="submit"
            >
              Änderung speichern
            </button>
            <button
              className="btn right"
              onClick={() => {
                clicked = "delete";
              }}
              type="submit"
            >
              Token löschen
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    const style = {
      display: "-webkit-flex",
      WebkitFlex: "0 1 auto",
      flex: "0 1 auto",
      WebkitFlexDirection: "column",
      flexDirection: "column",
      WebkitFlexGrow: 1,
      flexGrow: 1,
      WebkitFlexShrink: 0,
      flexShrink: 0,
      WebkitFlexBasis: "25%",
      flexBasis: "25%",
      maxWidth: "25%",
      height: "200px",
      WebkitAlignItems: "center",
      alignItems: "center",
      WebkitJustifyContent: "center",
      justifyContent: "center"
    };
    return this.getDisplay(style);
  }
}

export default ManageRegisterTokens;
