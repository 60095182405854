import React from 'react'

class AddChapter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {chapterNameClass: ""}
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
   event.preventDefault();
   let error = false;
   if (event.target.chapterName.value === "") {
     error = true;
     this.setState({chapterNameClass: "has-error"})
   }
     if (!error) {
     this.props.onChapterAdd(event.target.chapterName.value,this.props.textName)
   }
}

 getDisplay(style)
 {
      return (
        <div>
        <br/>
        <form method="post" onSubmit={this.handleSubmit}>
        <div className="container form-group ">
          <div className={this.state.chapterNameClass}>
          <label className="control-label"><b>Name des Kapitels: </b></label><br/>
          <input type="text" className="fullInput form-control" placeholder="Kapitel Name" name="chapterName"  />
          {this.state.chapterNameClass === "has-error" ? (<div><span className="help-block">Bitte dieses Feld ausfüllen</span><br/></div>) : ""}
          </div>
          <button className="btn  " type="submit">Neues Kapitel Hinzufügen</button>
        </div>
      </form>

      </div>
      )
 }

  render() {
    const style = {
           display: '-webkit-flex',
           WebkitFlex: '0 1 auto',
           flex: '0 1 auto',
           WebkitFlexDirection: 'column',
           flexDirection: 'column',
           WebkitFlexGrow: 1,
           flexGrow: 1,
           WebkitFlexShrink: 0,
           flexShrink: 0,
           WebkitFlexBasis: '25%',
           flexBasis: '25%',
           maxWidth: '25%',
           height: '200px',
           WebkitAlignItems: 'center',
           alignItems: 'center',
           WebkitJustifyContent: 'center',
           justifyContent: 'center'
       };
    return (
      this.getDisplay(style)
    );
  }
}

export default AddChapter;
