import {SET_TEXTS} from '../actions/textActions'
import { toast } from 'react-toastify';
import {authenticateAndSendToAPI} from './connection'

export const getTextsByUser = (dispatch) => {
  const query = `
  {
    userTexts()
    {
      textName
      isReadOnly
      subtitle
    }
  }
  `

  authenticateAndSendToAPI(query,(result) => {    
    dispatch(SET_TEXTS(result.userTexts))
  }, error => { toast.error(error.message)})

}
