import {connect} from 'react-redux'
import ManageRegisterTokens from '../Components/manageRegisterTokens'
import {getAllClasses} from '../Fetching/classes'
import {getAllRegisterTokens, deleteRegisterToken, addRegisterToken, updateRegisterToken} from '../Fetching/registerTokens'

const mapDispatchToProps = dispatch => {
  return {
    onLoad: () => {getAllClasses(dispatch); getAllRegisterTokens(dispatch)},
    deleteRegisterToken: (registerToken) => {deleteRegisterToken(registerToken,dispatch) },
    addRegisterToken: (registerToken,validUntil,userCount,className,role) => {addRegisterToken(registerToken,validUntil,userCount,className,role,dispatch)},
    updateRegisterToken: (oldRegisterToken, newRegisterToken,validUntil,userCount,className,role) => {updateRegisterToken(oldRegisterToken, newRegisterToken,validUntil,userCount,className,role,dispatch)}
  }
}

const mapStateToProps = (state) => {
  return {
    classes: state.allClasses,
    registerTokens: state.registerTokens
  }
}

const ManageRegisterTokensConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageRegisterTokens)

export default ManageRegisterTokensConnect;
