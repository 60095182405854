import { LOAD_REGISTERTOKENS } from '../actions/registerTokenActions'
import { toast } from 'react-toastify';
import { sha512 } from 'js-sha512'

import { authenticateAndSendToAPI, clientgraph } from './connection'

export const getAllRegisterTokens = (dispatch) => {
  const query = `
  {
    registerTokens()
    { 
      token
      className 
      remainingUsers
      roleId
      validUntil
    }
  }
  `

  authenticateAndSendToAPI(query, (result) => {
    dispatch(LOAD_REGISTERTOKENS(result.registerTokens))
  })
}

export const deleteRegisterToken = (registerToken, dispatch) => {
  const query = `
  mutation
  {
    deleteRegisterToken(registerToken: "`+ registerToken + `")
  }
  `
  authenticateAndSendToAPI(query, (result) => {
    if (result.deleteRegisterToken) {
      getAllRegisterTokens(dispatch)
      toast.success("RegisterToken wurde erfolgreich gelöscht!")
    } else {
      toast.error("RegisterToken konnte nicht gelöscht werden.")
    }

  })
}

export const addRegisterToken = (registerToken, validUntil, userCount, className, role, dispatch) => {
  const query = `
  mutation
  {
    addRegisterToken(registerToken: "`+ registerToken + `" validUntil: "` + validUntil + `" userCount: ` + userCount + ` className: "` + className + `" role: ` + role + `)
  }
  `
  authenticateAndSendToAPI(query, (result) => {

    if (result.addRegisterToken) {
      getAllRegisterTokens(dispatch)
      toast.success("RegisterToken wurde erfolgreich erstellt!")
    } else {
      toast.error("RegisterToken konnte nicht erstellt werden.")
    }

  }, error => toast.error(error.message.replace("GraphQL Error:","")))
}

export const updateRegisterToken = (oldRegisterToken, newRegisterToken, validUntil, userCount, className, role, dispatch) => {

  const query = `
  mutation
  {
    editRegisterToken(oldRegisterToken: "`+ oldRegisterToken + `" registerToken: "` + newRegisterToken + `" validUntil: "` + validUntil + `" userCount: ` + userCount + ` className: "` + className + `" role: ` + role + `)
  }
  `

  authenticateAndSendToAPI(query, (result) => {
    if (result.editRegisterToken) {
      getAllRegisterTokens(dispatch)
      toast.success("RegisterToken erfolgreich bearbeitet!")
    } else {
      toast.error("RegisterToken konnte nicht bearbeitet werden!")
    }
  })
}

export const tryRegisterWithToken = (username, password, registerToken) => {

  const query = `
  mutation
  {
    registerWithRegisterToken(newUserName: "`+ username + `" registerToken: "` + registerToken + `" newPassword: "` + sha512(password).toUpperCase() + `")
  }
  `

  clientgraph.query(query).then(result => {
    if (result.registerWithRegisterToken) {
      toast.success("Erfolgreich registriert!")
    } else {
      toast.error("User konnte nicht registriert werden!")
    }
  })
}