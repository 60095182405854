import {connect} from 'react-redux'
import ManageQuiz from '../Components/manageQuiz'
import { addQuizToChapter } from '../Fetching/chapters';

const mapDispatchToProps = dispatch => {
  return {
    addQuiz: (textName,chapterId, quizUrl) => { addQuizToChapter(dispatch, textName, chapterId, quizUrl)}
  }
}

const mapStateToProps = (state) => {
  return {
    quizUrl: state.quizUrl,
    chapterId: state.changeText[0],
    textName: state.currentText.textName
  }
}

const ManageQuizConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageQuiz)

export default ManageQuizConnect;
