import { toast } from 'react-toastify';
import {authenticateAndSendToAPI} from './connection'

export const markTextOnApi = (chapterId, markers, textName) => {

 let markersString = ""
 for (let index = 0; index < markers.length; index++) {
   const marker = markers[index];
   markersString += "{startTag: \""+ marker.startTag.replace(/\"/g, "\\\"") +"\" endTag: \""+ marker.endTag.replace(/\"/g, "\\\"") +"\" startIndex: "+ marker.startIndex+" length: "+marker.length+"}"
 }

  const query = `
  mutation
  {
     markText(chapterId: `+chapterId+` markers: [`+markersString+`] textName: "`+textName+`")
  }
  `
  authenticateAndSendToAPI(query,(data) =>{
    if (!data.markText) {
       toast.error("Die Änderung konnte nicht gespeichert werden")
    } else {
      toast.success("Die Änderung wurde erfolgreich gespeichert")
    }
  });
}
